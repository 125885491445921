.article-body-container{
    border-top: 1px solid lightgray;
    padding: 30px 0px 50px 0px;
}

.article-body-container p{
    color: #2F2C41;
}

.article-body-container li{
    color: #2F2C41;
}

.article-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.article-card-user-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    z-index: 4;
    cursor: pointer;
    min-width: 33%;
}

.article-card-user-container p{
    text-align: center;
    margin: 10px;
    font-weight: bold;
}

.article-card-user-container h2{
    text-align: center;
}

.activity-list{
    align-items: center;
    padding: 20px 20px 20px 20px;
    background-color: white;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    border-radius: 5px;
    margin: 30px;
    text-align: center;
    width: 80%;
}

.introduction-card img{
    border-radius: 50%;
    padding: 5px;
    height: 100px;
    width: 100px;
}

.allActivity-banner{
    width: 100%;
    border-radius: 5px;
}

.massage-container{
    display: flex;
    align-items: center;
    width: 100%;
    margin-left: 80px;
  }

  .massage-container img{
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-left: 20px;
  }

  .messagebar-container{
      height: auto;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      border-radius: 5px;
      padding: 20px;
      bottom: 0px;
  }

  .reaction-bar-container{
      display: flex;
      justify-content: space-between;
  }

  .reaction-bar-container img{
    width: 20px;
    height: 20px;
    margin: 10px;
    cursor: pointer;
}

  .messagebar-container img{
      width: 20px;
      height: 20px;
      margin: 10px;
      cursor: pointer;
  }

  .messagebar-container input{
      width: 78%;
  }

  .reaction-card{
    height: auto;
    width: 84%;
    margin: 30px 10px 30px 10px;
    border-radius: 5px;
    background-color: #FFF;
    padding: 20px;
  }

  .introduction-card p{
    text-align: center;
    margin: 30px 50px;
}

#members-container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px;
    cursor: pointer;
    padding: 5px;
    border: 1px solid lightgray;
    border-radius: 5px;
}

#members-total-count-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

#members-total-count-container h4{
    padding: 10px;
    color: gray;
    border: 1px solid gray;
    border-radius: 50%;
    margin-left: 10px;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.message-input-content-editable{
    width: 94%;
    min-height: 100px;
    box-shadow: inset 0 1px 2px grey;
    border-radius: 5px;
    padding: 10px;
    background: white;
}

.likes-outer-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.my-like-container{
    background-color: white;
    border-radius: 5px;
    padding: 20px;
    margin: 10px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    width: 80%;
}

.chat-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    width: 90%;
    background-color: white;
    margin: 20px;
    border-radius: 5px;
}

.like-icon-inner-container{
    margin: 0px;
    cursor: pointer;
}

.like-icon-inner-container img{
    width: 30px;
    height: 30px;
}

.like-container{
    margin-bottom: 20px;
}

  .like-counter{
      margin-left: 10px;
  }

  .auth-message{
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    word-break: break-word;
    padding: 15px;
    border-radius: 15px;
    font-size: 16px;
    margin: 15px 15px;
    max-width: 50%;
    background-color: white;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  }

  .sender-meta-container{
      display: flex;
      align-items: center;
  }

  .sender-photo{
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 5px;
      cursor: pointer;
  }

  .sender-name{
      align-self: flex-end;
      font-size: 12px;
      font-weight: bold;
      margin: 10px;
      color: #2F2C41;
      cursor: pointer;
  }

  .sender-timestamp{
      align-self: flex-start;
      font-size: 12px;
  }

  .notifications-icon-message{
      width: 15px;
      align-self: left;
      cursor: pointer;
      margin-left: 2px;
  }

  .group-header{
      height: 150px;
      background-color: white;
      border-bottom: 5px solid #a7bdbd60;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
  }

  .landing-outer-container{
      margin-bottom: 100px;
      margin-top: 50px;
      width: 80%;
  }

  .group-landing-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.group-landing-container img{
    width: 100%;
    object-fit: cover;
    height: 200px;
}

#group-landing-description{
    padding: 30px 30px 0px 30px;
}

.auth-message-container img{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;
    object-fit: cover;
}

.message-card-timestamp{
    margin: 0px 0px 0px auto;
    font-size: 12px;
}

.messagebar-group-container{
    bottom: 0px;
    top: auto;
    position: fixed;
    width: 55%;
    margin-bottom: 5px;
    padding-top: 5px;
}

.user-image{
    cursor: pointer;
}

.approval-message-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 50px;
    min-height: 100vh;
    width: 95%;
}

.approval-message-container img{
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

.approval-message-container a{
    color: green;
    margin-top: 30px;
}

.messagebar-group-inner-container{
    display: flex;
    align-items: center;
    margin-bottom: 70px;
}

#welcome-banner{
    width: 100%;
}

.messagebar-group-container img{
    width: 20px;
    height: 20px;
    margin: 10px;
    cursor: pointer;
}

.messagebar-group-container textarea{
    min-height: 75%;
}

.chat-header img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.chat-header h2{
    margin: 0px 5px 10px 5px;
}

.chat-container{
    width: 90%;
    flex-direction: column;
    align-items: center;
}

.chat-screen{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px 100px 20px;
    background-color: white;
    min-height: 100vh;
    width: 90%;
    border-radius: 5px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    margin-bottom: 30px;
}

.group-navigation-container{
    display: flex;
    align-self: flex-start;
    margin-left: -20px;
}

.group-navigation-container p{
    padding: 5px;
    cursor: pointer;
    border-radius: 3px;
    color: #2F2C41;
    font-weight: bold;
    margin-bottom: -20px;
}

#reactions-container{
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    background-color: white;
    border-radius: 5px;
    padding: 20px;
    margin-top: 20px;
}

.message-detail-container{
    margin-left: 10%;
}

.reaction-container{
    background-color: white;
    margin: 20px;
    border-radius: 5px;
    padding: 20px;
    margin: 5px 0px 30px 0px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.answer-area-container{
    margin: -5px 0px 0px 10px;
    cursor: pointer;
}

.massage{
    color: #2F2C41;
  }

  .message-container{
      padding: 15px;
      border-radius: 5px;
      margin-left: 16px;
      background-color: #83edff14;
  }

  .like-container img{
    height: 20px;
    width: 20px;
    cursor: pointer;
  }

  .auth-message{
    cursor: pointer;
    margin-right: 5px;
    color: #d11237;
  }

  .reaction-outer-container{
      padding: 20px;
      border-left: 1px solid lightgray;
  }

  .options-icon img{
      margin-right: 10px;
      height: 20px;
      width: 20px;
  }

  .like-icon-container{
    display: flex;
    align-items: center;
    margin-left: 4px;
}

.like-icon-inner-container{
    display: flex;
    margin-right: 10px;
}

.like-icon-inner-container p{
    padding: 2px;
    font-size: 11px;
    margin-left: 0px;
}

.article-detail-banner{
    margin-bottom: -38px !important;
    border-radius: 5px;
}

.article-inner-div{
    padding: 30px;
    display: flex;
    flex-direction: column;
  }

  .article h2{
      text-align: left;
  }

  .message-card{
    height: auto;
    width: 84%;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    margin: 30px 10px 30px 10px;
    border-radius: 5px;
    background-color: #FFF;
    padding: 20px;
  }

  .delete-message-container{
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    border-radius: 5px;
  }

  .delete-message-container img{
      margin-right: 10px;
  }

  .card-overview{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
  }

  .send-message-as-email-button{
      border-radius: 3px;
      border: 1px solid lightgray;
      padding: 6px;
      color: gray;
  }

  .tag-container{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-left: 60px;
      margin-bottom: 5px;
  }

  .tab-container{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
  }

  .tag-container img{
      height: 20px;
      width: 20px;
      margin-right: 5px;
  }

  .tagged-user{
      cursor: pointer;
      border-radius: 50%;
  }

@media only screen and (max-width: 1024px){

    .massage-container {
        margin-left: 0px;
    }

    .message-detail-container{
        margin-left: 0px;
    }

    .messagebar-group-container {
        z-index: 10;
        width: 100% !important;
        background-color: white;
    }

    .messagebar-group-inner-container {
        margin-bottom: 0px;
    }
}