.color-picker-color-container{
    height: 20px;
    width: 20px;
    border-radius: 5px;
}

.color-picker-select-color-container{
    display: flex;
    margin-bottom: 5px;
}

#dashboard-period-selector-container{
    width: 94%;

}

#dashboard-period-selector-container h2{
    text-align: left;
}

.home-sections-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 96.5%;
}

.home-section-container {
    background-color: white;
    padding: 30px;
    border-radius: 5px;
    border: 1px solid #f4f4f4;
    margin: 20px;
    flex: 1 1 25%; /* This allows the width to start at 25% but grow/shrink dynamically */
    box-sizing: border-box; /* Ensures padding and border don't affect the width calculation */
    overflow-x: scroll;
}

.home-sections-column-container {
    display: flex;
    height: 100%; /* Ensure the container fills the available height */
    width: 100%;
  }

.home-sections-one-column-container{
    display: flex;
    flex-direction: column;
    width: 100%;
}

#presence-options-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#presence-options-container svg {
    width: 25px;
    height: 25px;
    margin-right: 5px;
    cursor: pointer;

}