#data-indexing-title-container{
    display: flex;
    align-items: center;
}

#data-indexing-title-container input{
    margin: 0px 5px 10px 5px;
}

#data-indexing-title-container svg{
    cursor: pointer;
}