#periodselector-card-button-container {
    padding: 15px 10px 10px 10px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #f4f4f4;
}

#periodselector-card-button-container button:first-child {
    background-color: red;
    color: white; 
    border: none; 
    cursor: pointer; 
}

#periodselector-card-button-container button:nth-child(2) {
    background-color: green;
    color: white; 
    border: none; 
    cursor: pointer; 
}

#period-selector-outer-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 94%;
}

.period-select-container{
    display: flex;
    width: 100%;
}

.period-select select{
    border: 1px solid #f4f4f4;
    font-size: 12px;
}

#range-picker-container{
    position: absolute;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #f4f4f4;
    margin-left: 80px;
}

.range-picker-input-container{
    display: flex;
    align-items: center;
}

.range-picker-input-container input{
    margin-left: 10px;
    margin-right: 10px;
    border: none;
    box-shadow: none;
    padding: 0px;
}

#range-selector-selected-range-container{
    cursor: pointer;
}

#selected-range-container{
    display: flex;
    align-items: center;
    width: 500px;
}

#home-range-picker-icon{
    height: 15px;
    width: 15px;
    cursor: pointer;
    margin: 0px 5px 0px 10px;
}
