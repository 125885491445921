#progress-bar-portfolio-project-container{
    height: 10px;
    width: 90%;
    border:1px solid #000;
    padding: 0;
}

#progress-bar-portfolio-project-progress-container{
    height: 10px;
    margin: 0;
}

.add-new-project-modal-input-container{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
}

.add-new-project-modal-input-container input{
    margin-top: 10px;
}

#add-new-project-modal-image{
    width: 100%;
    height: 200px;
    margin-top: 10px;
    object-fit: cover;
    border-radius: 5px;
}