.calendar-event-container{
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
}

.calendar-event-container p{
    margin-top: 0px;
    margin-bottom: 0px;
}

.calendar-event-title{
    margin-left: 5px;
}

.calender-container{
    width: 100%;
    margin-bottom: 100px;
}

.month-container{
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto;
    background-color: white;
}

.calendar-weekdays{
    margin: 10px 0px -8px 0px;
    font-weight: 600;
}

.day-container{
    min-height: 100px;
    width: auto;
    border: 1px solid lightgray;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 35px;
}

.current-day{
    background-color: lightgray;
    border-radius: 50%;
    padding: 5px;
}

.calendar-navigation-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.calendar-navigation-container button{
    border-radius: 5px;
    color: white;
    margin-right: 20px;
}