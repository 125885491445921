.dropdown {
    position: relative;
    width: 300px;
    margin: 0px;
}

.dropdown-header {
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dropdown-header .arrow {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transition: transform 0.3s ease;
}

.dropdown-header .arrow.open {
    transform: rotate(45deg);
}

.dropdown-list {
    position: absolute;
    width: 100%;
    border: 1px solid #ccc;
    background-color: #fff;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
}

.dropdown-item {
    padding: 10px;
    cursor: pointer;
}

.dropdown-item:hover {
    background-color: #f0f0f0;
}

.dropdown-type-container{
    border-bottom: 1px solid #ccc;
}