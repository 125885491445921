#all-funds-filter-container{
    /* display: flex; */
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
    width: 100%;
}

.all-funds-fund-container{
    padding: 20px;
    border: 1px solid #f4f4f4;
    border-radius: 5px;
    background-color: white;
    margin-bottom: 20px;
}

.all-funds-fund-container h2{
    text-align: left;
}

.all-funds-fund-logo-img{
    width: 100px;
}