.download-data-container{
    display: flex;
    flex-direction: column;
}

.download-data-container button{
    width: 200px;
    margin-top: 30px;
}

#download-svg-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}