.subscription-crad-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #f4f4f4;
    border-radius: 5px;
    padding: 20px;
    width: 200px;
    height: 400px;
    background-color: #47aac2;
    border: 1px solid #47aac2;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
}

.subscription-crad-container h2, .subscription-crad-container p{
    color: white;
}

.subscription-crad-container button{
    background-color: #f48183;
    border: #f48183;
    color: white;
}

.subscription-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #47acc3;
    border-radius: 5px;
    width: 250px;
    padding: 20px;
    margin: 10px;
}

.subscription-container h2{
    color: white;
}

.subscription-container h3{
    color: white;
}

.subscription-container p{
    color: white;
}

.subscription-container img{
    width: 100%;
    height: 200px;
}

#subscription-card-price-explainer{
    font-size: 12px;
    margin-top: 10px;
}

#subscribe-button-container{
    width: 90%;
    margin-top: 10px;
}

#create-account-container input{
    background-color: white;
}

#create-account-container h2{
    text-align: left;
}

#create-account-dummy-logo{
    margin-top: 20px;
}

#mandetory-notice{
    font-size: 10px;
    text-align: left;
    width: 90%;
}

.create-account-input-container{
    margin-bottom: 30px;
    width: 90%;
}

.upgrade-notice-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.upgrade-notice-container img{
    height: 200px;
}

#subscription-card-container{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}

.subsciption-card-features-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px dotted white;
}

.subsciption-card-features-container p{
    margin: 0px;
}

.subsciption-card-features-container svg{
    margin-right: 10px;
    color: white;
}

.subscription-card-features-item-container{
    display: flex;
    align-items: center;
}

.subsciption-card-features-outer-container{
    margin-top: 30px;
    width: 100%;
}

.subscription-card-information-icon{
    height: 20px;
}