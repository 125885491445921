.toggle-container {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .toggle-switch {
    position: relative;
    width: 48px;
    height: 24px;
  }
  
  .toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 12px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .slider:before {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 50%;
    transition: transform 0.2s ease-in-out;
  }
  
  .toggle-switch input:checked + .slider {
    background-color: #00cc00;
  }
  
  .toggle-switch input:checked + .slider:before {
    transform: translateX(24px);
  }
  
  .toggle-label {
    font-size: 14px;
    font-weight: bold;
    color: #333;
  }

