#onboarding-item-container{
    position: fixed;
    z-index: 1000;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: 500px;
}

#onboarding-item-text-container h2{
    margin-top: 0px;
}

#onboarding-item-text-container{
    padding: 20px;
    background-color: white;
    border-radius: 5px;
}

#onboarding-overlay-container{
    filter: 'blur(5px)';
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#home-onboarding-container{
    padding: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    background-color: white;
    width: 90%;
    border-radius: 5px;
}

#home-onboarding-container h2{
    text-align: left;
}

#onboaring-modal-button-container{
    display: flex;
    justify-content: space-between;
    margin: 50px -20px -20px -20px;
    padding: 20px;
    background-color: #f3f7fd;
}

#onboarding-modal-close-button{
    background-color: #f48183;
    color: white;
    border: none;
    border-radius: 5px;
}

#onboarding-progress-bar-container{
    height: 10px;
    margin: -20px -20px 50px -20px;
}

#onboarding-progress-bar{
    height: 100%;
}

#onboarding-progress-bar-fill{
    height: 100%;
    background-color: #63cadc;
}

#breadcrumb-onboarding{
    width: 100%;
    margin-top: auto;
    margin-bottom: 0;
}