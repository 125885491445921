.report-container{
    width: 83%;
    border: 1px solid #f4f4f4;
    background-color: white;
}

.report-banner{
    height: 400px;
    width: 100%;
    object-fit: cover;
}

.report-logo{
    max-width: 300px;
    margin-left: 30px;
    max-height: 150px;
    margin-top: 30px;
}

.report-head-text{
    margin-left: 37px;
}

.report-inner-container{
    margin: 20px 40px 40px 40px;
}

.report-title{
    text-align: left;
    margin-left: 40px;
    font-size: 40px;
}

.report-section{
    margin-top: 100px;
}

.report-section h2{
    text-align: left;
    font-size: 30px;
}

.report-section-title-container{
    display: flex;
    align-items: center;
    margin-top: 100px
}

.report-section-title-container img{
    margin-right: 10px;
    height: 30px;
}

.report-section-title-container svg{
    margin-right: 10px;
    height: 30px;
}

.report-targetgroup-item-container{
    margin: 20px 40px 0px 0px;
    display: flex;
    justify-content: space-between;
}

.report-targetgroup-container{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 30px;
}

.report-section-container-contrast h2{
    color: white;
    font-size: 30px;
}

.report-section-container-contrast h3{
    color: white;
    margin-left: 40px;
}

.report-anecdote-container{
    padding: 20px;
    border: 1px dotted white;
    border-radius: 5px;
    margin: 40px 40px 0px 40px;
}

.report-anecdote-container h3{
    color: white;
}

.report-anecdote-container p{
    color: white;
}

.report-activity-output-container{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.report-activity-output-item-container{
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.report-activity-item-container{
    border-bottom: 1px solid lightgray;
    padding-bottom: 20px;
    display: flex;
    width: 100%;
    margin-top: 30px;
}

.report-settings-impact-banner{
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 5px;
}

.report-media-container{
    display: flex;
    flex-wrap: wrap;
    margin: 0px 10px 0px 0px;
    border: 1px solid lightgray;
    border-radius: 5px;
    background-color: white;
}

.report-media-container img{
    width: 75px;
    height: 75px;
    object-fit: cover;
    border-radius: 5px;
}

.report-media-container video{
    width: 75px;
    height: 75px;
    object-fit: cover;
    border-radius: 5px;
}

.report-media-item-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.preview-file-container img{
    width: 100px;
    border-radius: 5px;
}
.report-media-item-options{
    margin: 0px;
}

.report-media-item-options img{
    width: 20px !important;
    height: 20px !important;
    margin-top: 10px !important;
    cursor: pointer;
}

.report-media-container-dashboard{
    display: flex;
}

.report-media-dashboard-item-container img{
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 5px;
    margin: 5px;
    width: 80%;
}

.report-media-dashboard-item-container video{
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 5px;
    margin: 5px;
    width: 80%;
}

.report-activity-item-description-container{
    width: 35%;
    display: flex;
    flex-direction: column;
}

.report-activity-item-description-container p{
    margin-bottom: 30px;
}

.report-activity-item-graph-container{
    width: 65%;
}

.report-activity-item-graph-container h3{
    text-align: center;
}

.report-media-dashboard-item-container{
    display: flex;
    align-items: center;
}

.report-media-file-preview-container img{
    height: 100px;
    border-radius: 5px;
}

.report-media-file-preview-container{
    margin: 10px 10px 0px 0px;
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 20px;
}

.report-targetgroup-media-container{
    display: flex;
    flex-wrap: wrap;
}

.report-targetgroup-media-container img{
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 5px;
    margin: 5px;
}

.report-targetgroup-media-container video{
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 5px;
    margin: 5px;
}

.report-builder-container{
    width: 100%;
}

.report-builder-banner{
    height: 400px;
    width: 100%;
    object-fit: cover;
}

.report-builder-edit-banner{
    height: 25px;
    width: 25px;
    cursor: pointer;
    margin-top: -250px;
    background-color: white;
    border-radius: 5px;
    padding: 5px;
}

.report-header-item-container{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.report-header-item-container input{
    width: auto;
}
.add-report-builder-item-container img{
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-top: 20px;
}

.add-report-builder-item-container svg{
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-top: 20px;
}

.report-builder-edit-description{
    margin-top: 0px;
    background-color: transparent;
}

.delete-icon-report-builder{
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin-top: 20px;
}

.report-builder-sub-title-container{
    display: flex;
    align-items: center;
    width: 100%;
}

.report-builder-sub-title-container img{
    height: 25px;
    width: 25px;
    margin-left: 10px;
    cursor: pointer;
}

.report-builder-sub-title-container input{
    text-align: left;
    font-size: 30px;
    font-weight: bold;
}

.live-report-title-input{
    text-align: left;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 40px;
    background-color: transparent;
    font-weight: bold;
    border: none;
    box-shadow: none;
    margin-left: 30px;
    margin-top: 30px;
}

.add-live-report-item-container{
    display: flex;
    align-items: center;
}

.live-report-suggestion-icon{
    cursor: pointer;
}

.livereport-settings-range-item-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.livereport-settings-range-item-container input{
    width: 100% !important;
    margin-left: 15px;
}

#live-report-period-container{
    display: flex;
    align-items: center;
    margin-right: 30px;
}

#live-report-period-container p{
    margin-left: 10px;
}

#live-report-logo-period-container{
    display: flex;
    align-items: center;
}

#live-report-builder-content-container{
    width: 100%;
}

#live-report-effect-indicator-navbar{
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    margin: 0px 30px 0px 30px;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #f4f4f4;
}

.live-report-effect-indicator-navbar-button{
    font-weight: bold;
}

.live-report-effect-indicator-navbar-button-container{
    display: flex;
    align-items: center;
}

#live-report-effect-indicator-navbar img{
    height: 20px;
    width: 20px;
    margin: 10px;
}

#live-report-open-question-show-reactions-container{
    display: flex;
    cursor: pointer;
    align-items: center;
}

#live-report-open-question-show-reactions-container p{
    margin-left: 10px;
}

#text-reponses-container{
    width: 90%;
}

#live-report-open-question-slider{
    margin-left: 30px;
    margin-right: 30px;
    padding: 20px;
    border: 1px solid #f4f4f4;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-bottom: 5px;
    width: 90%;
}

#live-report-open-question-slider p{
    font-size: 13px;
}

#live-report-open-question-text-indicator-navbar-button{
    font-size: 13px;
}

#live-report-open-question-text-count-indicator{
    font-size: 13px;
}

#indicator-data-info-options-container{
    display: flex;
    align-items: center;
}

#indicator-data-info-options-container img{
    cursor: pointer;
}

.select-storytelling-item-container{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: 1px solid #f4f4f4;
    padding-bottom: 5px;
    justify-content: space-between;
}

.select-storytelling-item-container input{
    width: 30px;
    box-shadow: none;
    border: none;
    margin-right: 10px;
}

.select-storytelling-item-container img, .select-storytelling-item-container video{
    height: 50px;
}

#live-report-media-dashboard-qoute-container{
    display: flex;
    margin-top: 30px;
}

#live-report-media-dashboard-qoute-container h2{
    margin: 0px 5px;
}

#live-report-media-dashboard-qoute-container p{
    margin-left: 5px;
}

#live-report-media-dashboard-anecdote-container{
    padding: 10px;
    border: 1px solid #f4f4f4;
    border-radius: 5px;
    margin-top: 30px;
}

.live-report-graph-options-container{
    position: absolute;
    margin-top: 30px;
}

.live-report-graph-options-container p{
    cursor: pointer;
    padding-bottom: 10px;
    border-bottom: 1px solid #f4f4f4;
}

.update-graph-color-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f4f4f4;
    padding-bottom: 10px;
    padding-top: 10px;
}

.update-graph-type-container{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f4f4f4;
    padding-bottom: 10px;
    padding-top: 10px;
}

.update-graph-type-container input{
    width: 25px;
    box-shadow: none;
    padding: 0px;
    margin-left: 10px;
}

.update-graph-type-container p{
    margin-left: 10px;
}

.update-graph-type-container svg{
    margin-left: 10px;
}

.update-graph-color-container input{
    width: 25px;
    box-shadow: none;
    padding: 0px;
    margin-left: 10px;
}

#live-report-period-selector-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 50px;
}

#live-report-period-selector-container svg{
    cursor: pointer;
    font-size: 16px;
}

#live-report-periode-selectetor-dates-container{
    display: flex;
}

#live-report-periode-selectetor-dates-container p{
    margin: 0px 10px;
    font-size: 18px;
}

#live-report-period-container{
    margin-left: 42px;
    margin-top: 50px;
}

#live-report-open-period-container{
    display: flex;
    align-items: center;
    padding-top: 45px;
    margin-left: 30px;
}

#live-report-open-period-container p{
    font-size: 20px;
}

.print-report-activity-item-description-container h3{
    font-size: 22px;
}

#live-reports-overview-date-range-container{
    display: flex;
}

.recharts-legend-wrapper{
    margin-right: 15px;
}

#live-report-media-container{
    margin-top: 30px;
}