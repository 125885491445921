.print-icon-container{
    margin: 50px;
    display: flex;
    align-items: center;
}

.print-icon-container img{
    height: 20px;
    cursor: pointer;
}

.print-icon-container p{
    cursor: pointer;
}

.print-go-back-container{
    display: flex;
    align-items: center;
}

.print-go-back-container img{
    height: 20px;
    cursor: pointer;
    margin-right: 10px;
}

.print-go-back-container p{
    cursor: pointer;
}

#print-questionniare-container{
    width: 90%;
    padding: 20px;
}

.questionnaire-field-container{
    margin-bottom: 30px;
}

#print-questionnaire-fields-container{
    margin-top: 50px;
}

#print-live-report-container{
    width: 100%;
    padding: 20px;
}

.print-report-activity-item-container{

}

#print-description-content-container{
    display: flex;
}

#print-description-content-container p{
    width: 50%;
}

.print-graph-wrapper{
    width: 1000px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#print-outputs-period-container{
    display: flex;
    margin-top: 50px;
    align-items: baseline;
}

#print-outputs-period-container h2{
    margin-right: 5px;
    margin-bottom: 20px;
}

.table {
    display: table;
    width: 100%;
    border-collapse: collapse;
}

.table-row {
    display: table-row;
}

.table-cell {
    display: table-cell;
    padding: 10px;
    border: 1px solid #ccc;
}

.header .table-cell {
    font-weight: bold;
    background-color: #f4f4f4;
}

#print-outputs-container h1{
    text-align: left;
    margin-left: 0px;
}

.print-questionaire-paragraph-container{
    height: 250px;
    border: 1px solid #f4f4f4;
    border-radius: 5px;
}

.print-questionaire-paragraph-container p{
    display: none;
}

.questionaire-field-print-container{
    margin-top: 100px;
}

@media print {
    .print-icon-container{
        display: none;
    }

    .print-go-back-container{
        display: none;
    }

    .print-content-container{
        margin-top: 50px;
    }

    .home-kpi-container{
        break-inside: avoid;
    }
}