#top-bar-container{
  display: block;
}

.top-bar{
  display: flex;
  padding: 5px 0px;
  min-height: 53px;
  align-items: center;
  justify-content: space-between;
}

.top-bar-logo{
  max-height: 50px;
  margin-left: 30px;
  cursor: pointer;
  max-width: 200px;
}

.icon-bar{
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: auto;
  cursor: pointer;
}

.icon-bar img{
  width: 19px;
  height: 19px;
  margin: 7px;
}

#search-bar{
  padding: 0px;
  cursor: pointer;
}

#profilePhoto{
  margin: 0px 50px 0px 0px;
  cursor: pointer;

}

#profilePhoto img{
  width: 30px;
  border-radius: 50%;
}

#search-bar-container{
  margin: 30px;
  width: 80%;
}

#search-bar-container input{
  border-radius: 5px;
}

#search-item-container{
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  margin: 10px;
  background-color: white;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.icon-bar-icon{
  width: 19px;
  height: 19px;
  margin: 7px;
}

.icon-container{
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0px 3px;
}

.icon-container:hover{
  background-color: white;
  border-radius: 50%;
}

#saved-bar{
  display: flex;
  background-color: #07b907;
  justify-content: center;
  align-items: center;
  height: 35px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

#saved-bar p{
  color: white;
  margin: 0px;
  font-size: 12px;
}

#subscribtion-container{
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
}

#subscribtion-container p{
  margin: 0px;
  font-size: 10px;
}

#subscribtion-container svg{
  margin-right: 5px;
  cursor: pointer;
  width: 15px;
}

#upgrade-cta{
  margin-left: 5px;
  cursor: pointer;
  font-weight: bold;
}

.profile-photo img{
  width: 40px;
  height: 40px;
  background: #a7bdbd26;
  border-radius: 50%;
  margin-right: 10px;
}

#photo-container-topbar{
  display: flex;
  align-items: center;
  cursor: pointer;
}

#topbar-saved-icon{
  margin-right: 5px;
}

.profile-photo{
  margin-left: 10px;
}

#notification-badge{
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px;
  font-size: 10px;
  position: absolute;
  margin-left: 15px;
  margin-top: -20px;
  height: 10px;
  width: 10px;
}

#notification-badge p{
  margin: -3.5px 0px 0px 1px;
  padding: 0px;
  color: white;
}

#topbar-icons-container{
  display: flex;
  align-items: center;
  justify-content: end;
  margin-right: 30px;
}

#topbar-breadcrumb-links-container{
  display: flex;
  align-items: center;
  justify-content: start;
  margin-left: 30px;
}

#topbar-breadcrumb-links-container p{
  margin-right: 5px;
  font-weight: 400;
}

#topbar-breadcrumb-links-container p:hover{
  cursor: pointer;
}

#profile-menu{
  display: none;
  flex-direction: column;
  position: absolute;
  background-color: white;
  width: 250px;
  border-radius: 5px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  z-index: 1;
  margin-left: -170px;
}

.profile-menu-group-container{
  margin: 0px 10px 0px 10px;
  border-bottom: 1px solid #f4f4f4;
}

.profile-menu-group-container p{
  cursor: pointer;
}

.profile-menu-group-container p:hover{
  font-weight: bold;
}

.profile-menu-group-container select{
  margin: 10px 0px;
}

#topbar-user-icon{
  font-size: 30px;
}

#profile-menu-user-info-container{
  display: flex;
  align-items: center;
  padding: 10px;
}

#profile-menu-name-container p{
  margin: 2px;
}

#profile-menu-email{
  font-size: 12px;
}

#profile-menu-notifications-container{
  display: flex;
  align-items: center;
}

#notification-badge-profile-menu-container{
  margin-left: 5px;
  background-color: red;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  font-size: 10px;
  padding: 2px;
}

#notification-badge-profile-menu-container p{
  margin: -3.5px 0px 0px 1px;
  padding: 0px;
  color: white;
}