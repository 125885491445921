#vector-data-container{
    margin: 20px 0px;
}

#vector-data-container input{
    width: 94%;
}

.delete-icon-data-indexing{
    cursor: pointer ;
}

#qAndA-container{
    margin: 20px 0px;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    width: 90%;
}

#qAndA-container h2{
    text-align: left;
}

#qanda-meta-container{
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}

.qanda-meta-item-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}