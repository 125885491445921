.kpi-container{
    background-color: white;
    padding: 10px;
    width: 97%;
    height: auto;
    align-items: flex-start;
    border: 1px solid #ebebeb;
    margin-top: 20px;
    border-radius: 5px;
}

.kpi-data-container{
    border-top: 1px solid lightgrey;
    padding: 5px;
    width: 300px;
    height: 100%;
    padding-top: 30px;
}

.select-datapoint p{
    cursor: pointer;
}

.kpi-source-inner-container{
    display: flex;
    align-items: center;
}

.kpi-datapoint-item-container{
    display: flex;
    align-items: center;
    border-bottom: 1px solid lightgray;
}

.kpi-datapoint-item-container img{
    margin-left: auto;
    margin-right: 10px;
    margin-bottom: 5px;
    height: 20px;
}

.kpi-research-meta-item-container p{
    text-align: center;
}

.kpi-data-container-big{
    width: 600px;
}

.kpi-research-meta-item-container{
    margin-left: 40px;
}

.kpi-research-meta-item-container p{
    text-align: left;
    font-size: 12px;
}

.kpi-research-meta-output-container{
    margin-left: 40px;
    display: flex;
}

.kpi-research-meta-output-container p{
    text-align: left;
    font-size: 12px;
    margin-left: 5px;
}

.kpi-data-container-no-border{
    border: none;
}

.button-add-kpi-container{
    margin: 10px 0px 30px 0px;
}

.kpi-proof-item-container{
    display: flex;
    align-items: center;
    border-bottom: 1px solid lightgray;
    width: 100%;
    justify-content: space-between;
}

.kpi-proof-item-container img{
    margin: 40px 30px 5px 0px;
    height: 20px;
    cursor: pointer;
}

.datapoint-research-graph-container button{
    margin-left: 50px;
}

.proof-kpi-description-container{
    margin: 0px;
}

.proof-kpi-description-container p{
    margin: 0px 10px 0px 0px;
}

.no-data-container p{
    text-align: center;
}

#kpi-proof-suggested-questions-container{
    display: flex;
    min-width: 1000px;
    margin: 0px;
    border-bottom: 1px solid #f4f4f4;
    justify-content: space-between;
}

.datapoint-research-option-container{
    display: flex;
    align-items: center;
    cursor: pointer;
}

.datapoint-research-option-container p{
    margin-right: 10px;
}

.selected-kpi-effect-mkba-title-container{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 100%;
}

.selected-kpi-effect-mkba-title-container p{
    margin-right: 5px;
}

#select-proof-type-container{
    display: flex;
    align-items: center;
    width: 100%;
}

.select-proof-type-item-container{
    cursor: pointer;
    border: 1px solid #f4f4f4;
    border-radius: 5px;
    margin-right: 5px;
    padding: 5px;
    display: flex;
    align-items: center;
}

.select-proof-type-item-container img{
    margin-right: 5px;
    height: 15px;
    width: 15px;
}

.select-proof-type-item-container:hover{
    background-color: #f4f4f4;
}

#select-kpi-modal-container select{
    width: 100%;
}

#kpi-select-questions-item-container input{
    width: auto;
}

#scale-visualisation-title-container{
    width: 100%;
}

#scale-visualisation-title-container p{
    margin-left: 5px;
}

#scale-visualisation-scale-item-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
}

#scale-visualisation-scale-container{
    width: 100%;
}

.scale-visualisation-label-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

#scale-visualisation-scale-box-container{
    width: 70%;
    border-bottom: 1px solid gray;
    margin-bottom: 150px;
}

#scale-visualisation-avarage-score-item-container{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

#scale-visualisation-avarage-score-item-container h1{
    margin-bottom: 10px;
}

#kpi-research-save-button{
    margin-top: 20px;
}