#indicator-effect-item-title-container{
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
}

#indicator-effect-item-title-container img{
    height: 20px;
    cursor: pointer;
}

#indicator-effect-item-container{
    border-bottom: 1px solid #e0e0e0;
    padding: 5px;
    margin-bottom: 5px;
}

#indicator-effect-icons-container{
    display: flex;
    align-items: center;
}

#indicator-effect-icons-container img{
    margin-left: 10px;
}

#indicator-effect-icons-container p{
    margin-top: -25px;
    padding: 2px;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    color: white;
    margin-left: -3px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #216d7f;
}

#indicator-indicators-container{
    padding: 10px;
}

#options-icon{
    cursor: pointer;
}

#home-kpi-options-outer-container{
    display: flex;
    align-items: flex-end;
    width: 100%;
}

.home-kpi-option-container{
    display: flex;
    justify-content: space-between;
}

.home-kpi-option-container p{
    margin-left: 5px;
}

.home-kpi-options-container{
    padding: 5px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #f4f4f4;
    position: absolute;
    margin-left: -9%;
    z-index: 50;
}

.home-kpi-indicator-visibility-option{
    cursor: pointer;
}

.home-kpi-indicator-visibility-option:hover{
    font-weight: bold;
}

.hom-kpi-option-select-container{
    display: flex;
    align-items: center;
    margin: 0px 0px 0px auto;
}

.hom-kpi-option-select-container img{
    font-size: 30px;
    cursor: pointer;
}

#pie-chart-label-text{
    font-size: 14px;
}

#matrix-one-data-total-average-container{
    display: flex;
}

#matrix-one-data-total-average-container p{
    margin-left: 5px;
}

#add-inicator-meetstandaard-effect-title-container{
    display: flex;
}

#add-inicator-meetstandaard-effect-title-container p{
    margin-left: 5px;
    font-size: 15px;
    color: #616161;
}

#chart-options-container{
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 90%;
    padding-top: 20px;
    border-top: 1px solid #f4f4f4;
    margin-top: 10px;
}

#chart-options-container svg, #chart-options-container img{
    height: 25px;
}

#parargraph-moment-visibility-style-option-container{
    display: flex;
}