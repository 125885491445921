.moment-title-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.moment-title-container p{
    color: green;
    cursor: pointer;
    margin-left: 10px;
}

.measure-moment-sub-container p{
    padding-bottom: 5px;
    border-bottom: 1px solid lightgray;
}

.measure-moment-sub-container select{
    margin-left: 20px;
}

.measure-moment-sub-container img{
    margin-left: 20px;
}

.measure-moment-sub-container h5{
    margin-left: 20px;
    font-family: 'Poppins', sans-serif;
    color: #616161;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.5px;
}

#response-count{
    margin-left: 20px;
    cursor: pointer;
}

.add-effect-container{
    display: flex;
    margin-bottom: 20px;
    align-items: center;
}

.add-effect-container img{
    margin-left: 10px;
    width: 30px;
    cursor: pointer;
}

.share-questionanire-button{
    cursor: pointer;
}

#research-tutorial-text-container h2{
    text-align: left;
}

.research-tutorial-text-item-container{
    padding: 20px;
    border: 1px solid #f4f4f4;
    border-radius: 5px;
    margin-bottom: 20px;
}

.start-research-step-title-container h2{
    text-align: left;

}

#categorisation-necessary-notice{
    color: #c00202;
    cursor: pointer;
}

#categorisation-done-notice{
    color: green;
    cursor: pointer;
}

#categorisation-not-necessary-notice{
    color: lightgray;
}

.categorisation-notice-item-container{
    display: flex;
    align-items: center;
}

.necessary svg{
    cursor: pointer;
    fill: #c00202;
}

.done svg{
    cursor: pointer;
    fill: green;
}

.not-necessay svg{
    fill: lightgray;
}

#field-responses-count{
    font-size: 12px;
}

#reserach-overview-responses-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

#research-builder-questionnairefields-count{
    cursor: pointer;
}

#research-details-container{
    width: 100%;
}

#research-details-item-container{
    width: 100%;
}

.reasearch-builder-option-container{
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid #f4f4f4;
    align-items: center;
}

.research-builder-options-state-container{
    display: flex;
    cursor: pointer;
}

.research-builder-options-title-container{
    margin-right: 50px;
}