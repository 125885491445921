#register-photo{
    height: 100px;
    width: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
}

#confirm-button-register-modal{
    color: #47acc3;
}
