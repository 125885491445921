#portfolio-syncs-add-effect-modal-effect-input-container input{
    width: 15px;
    border: none;
    box-shadow: none;
    margin-right: 10px;
}

#sync-builder-paired-effect-title-container{
    display: flex;
    align-items: center ;
}

#sync-builder-paired-effect-title-container svg{
    margin-left: 10px;
    font-size: 18px;
    cursor: pointer;
}

.portfolio-projects-sync-counter-container{
    margin-left: 30px;
    margin-top: -17px;
}

#sync-builder-create-output-button-container{
    justify-content: flex-start;
    margin-top: 10px;
}