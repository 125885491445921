.output-effects-container{
    width: 100%;
}

.output-effects-inner-container{
    width: 100%;
    margin-bottom: 15px;
    align-items: center;
    border: 1px solid #f4f4f4;
    background-color: white;
    border-radius: 5px;
    padding: 30px 0px;
}

.output-effects-meta-container{
    border-top: 1px solid lightgray;
    margin-right: 20px;
    margin-left: 20px;
}

.output-effects-meta-container select{
    background-color: transparent;
    border: none;
    box-shadow: none;
}

.output-effects-inner-container textarea {
    background-color: transparent;
    border: none;
    box-shadow: none;
    font-size: 18px;
}

.output-effects-inner-container select {
    background-color: transparent;
    border: none;
    box-shadow: none;
}

.sub-effects-container{
    margin-left: 30px;
    width: 95%;
}

.output-effects-dashborad-container{
    width: 90%;
}

.output-effects-outer-container{
    display: flex;
    align-items: center;
}

.output-effects-outer-container img{
    width: 20px;
    height: 20px;
}

.output-detail-container{
    padding: 5px;
}

.button-container-outputs{
    justify-content: flex-start !important;
    margin-left: 30px !important;
}

.effects-meta-item-container{
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgb(236, 235, 235);
}

.effects-meta-item-container p{
    margin-right: auto;
}

.effects-meta-item-value{
    margin-right: 5px !important;
}

.effects-meta-item-container select{
    border: 1px solid lightgray;
}

#add-result-input{
    width: 80%;
}