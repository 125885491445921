#helpdesk-banner{
    width: 100%;
    border-radius: 5px;
}

.helpdesk-banner-container{
    display: flex;
    justify-content: flex-end;
}

#helpdesk-container{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.helpdesk-item-container{
    width: 40%;
}

.helpdesk-contact-item-container{
    display: flex;
    align-items: center;
    margin: 10px 0px                                                         
}

.helpdesk-contact-item-container svg{
    margin-right: 20px;
}