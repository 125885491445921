.sroi-title-container img{
    margin-left: 10px;
    cursor: pointer;
}

.sroi-spec-container{
    margin-left: 50px;
    border-left: 1px solid lightgray;
}

.sroi-spec-container tr{
    width: 100%;
}

.sroi-benefit-container{
    border-left: 1px solid lightgray;
}

.sroi-save-amount-button{
    cursor: pointer;
    color: green;
    font-size: 14px;
    font-weight: bold;
    margin: 0px;
}

.specification-title-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px 5px;
    width: 100%;
}

.spec-detail-container{
    display: flex;
    align-items: center;
    border-bottom: 1px solid lightgray;
}

.spec-detail-container input{
    background-color: transparent;
    border: none;
    box-shadow: none;
}

.spec-detail-container select{
    background-color: transparent;
    border: none;
    box-shadow: none;
}

.sroi-benefits-detail-container{
    background-color: white;
    padding-right: 15px;
    border: 1px solid #d3d3d382;
}

.grand-total-amount{
    font-size: 18px;
    font-weight: bold;
}

#ultimate-total{
    font-size: 18px;
    font-weight: bold;
}

.sroi-detail-container{
    width: 80%;
    margin-bottom: 50px;
}

.sroi-detail-page-header{
    width: 90%;
}

#output-amount-container{
    display: flex;
    justify-content: center;
    min-width: 100%;
}

#output-title{
    padding: 0px 15px;
    cursor: pointer;
}