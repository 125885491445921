.theme-section-title-container{
    display: flex;
    align-items: center;
}

.theme-section-title-container svg{
    margin-right: 15px;
}

.theme-guide-section-title{
    text-align: left;
}

.theme-pair-container{
    padding: 10px;
    margin-left: 0px;
}

.theme-guide-kpi-pairs-item-title-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.theme-guide-kpi-pairs-item-title-container svg{
    margin-left: 10px;
    cursor: pointer;
}

#theme-detail-approve-modal-title-container p{
    text-align: center;
}