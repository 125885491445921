#next-step-container{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
}

#next-step-saved-container{
    margin-right: 20px;
    width: 100%;
    margin-left: 20px;
}

.next-step-saved-item-container{
    display: flex;
    align-items: center;
}

.next-step-saved-item-container p{
    margin-left: 5px;
    font-size: 12px;
}

.next-step-saved-item-container svg{
    font-size: 12px;
}

.impactstrategy-saved-and-nextstep-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
}