.questionnaire-title-container{
    display: flex;
    align-items: center;
}

.questionnaire-title-container p{
    color: green;
    cursor: pointer;
    margin-left: 10px;
}

.save-button-questionnaires{
    cursor: pointer;
    color: green;
    font-size: 12px;
}

.question-title-input{
    border: none;
    box-shadow: none;
    font-size: 18px;
    margin-bottom: 2px
}

.question-subtitle-textarea{
    border: none;
    box-shadow: none;
    color: grey;
}
.question-checkbox-input{
    width: 100%;
    border: none;
    box-shadow: none;
    font-size: 16px !important;
    padding: 0px 5px !important;
}

#options-container{
    display: flex;
    flex-direction: column;
    margin: 20px 10px;
    align-items: flex-start;
}

.question-multiple-delete-icon{
    height: 15px;
    margin-left: 10px;
    cursor: pointer;
}

.add-question-button{
    margin-top: 10px;
}

.questionnaire-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 84%;
}

.question-container{
    width: 100%;
}

.print-icon-container p{
    margin-left: 10px;
}

.questionnairefields-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.add-questionnairefield-container{
    display: flex;
    align-items: center;
    width: 91%;
    margin: 20px 10px 30px 20px;
    border-bottom: 1px solid lightgray
}

.add-questionnairefield-container img{
    height: 20px;
    cursor: pointer;
    margin: 5px;
}

.add-questionnairefield-container svg{
    height: 20px;
    cursor: pointer;
    margin: 5px;
}

#questionnaire-field-text{
    border: 1px dotted lightgray;
    padding: 20px;
    margin-top: 10px;
    background-color: white;
    border-radius: 5px;
}

#questionnaire-question{
    margin-top: 10px;
    width: 95%;
}

.question-type-display-container{
  padding: 0px 10px 20px 10px;
  margin: 20px 0px;
  width: 100%;
}

#scale-reach-symbol{
    font-weight: 600;
    margin-left: 10px;
    margin-top: 20px;
}

.questionnaire-field-item-container{
    width: 90%;
}

.questionnaire-field-delete-icon-container{
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
    margin-top: 10px;
    border-top: 1px solid lightgray;
}

.questionnaire-field-delete-icon-container img{
    height: 20px;
    cursor: pointer;
    margin-left: 10px;
}

.questionnaire-field-type-paragraph-container{
    border-bottom: 1px dotted lightgray;
    margin-top: 30px;
}

.questionnaire-field-type-paragraph-container p{
    color: rgb(176, 175, 175);
    margin-left: 12px;
}

.questionnaire-field-top-level-container{
    display: flex;
    justify-content: end;
    align-self: center;
}

.questionnaire-field-top-level-container input{
    width: 100% !important;
}

.questionnaire-field-top-level-container select{
    width: 200px !important;
    margin-right: 10px;
}

#scale-container{
    display: flex;
    justify-content: space-around;
    margin: 20px 0px;
    width: 100%;
}

#scale-container input{
    box-shadow: none;
    width: 11px;
    margin-bottom: 5px;
}

#question-type-label-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.select-scale-container{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.edit-scale-container{
    width: 100%;
    display: flex;
    margin-top: 30px;
}

.options-item-container{
    display: flex;
    align-items: center;
}

.options-item-container p{
    margin: 0px 0px 0px 10px;
    width: 164px;
}

.options-item-container input{
    width: auto !important;
    padding: 0px 5px !important;
    margin-left: 5px;
    font-size: 16px !important;
}

.options-item-container img{
    height: 15px;
    cursor: pointer;
    margin-left: 10px;
}

.share-questionnaire-container{
    margin-top: 20px;
}

.send-questionnaire-tabel-icon{
    height: 25px;
    cursor: pointer;
    margin-left: 10px;
}

.button-container-upload-questionnaire{
    margin-top: 20px;
}

.uplaoded-questions-container{
    margin-top: 20px;
}

.uploaded-question-container{
    padding: 5px;
    border: 1px solid lightgray;
    margin-top: 5px;
    background-color: white;
    border-radius: 5px;
    width: 90%;
}

.uploaded-responses-container{
    display: flex;
    padding: 5px;
    border: 1px solid lightgray;
    margin-top: 5px;
    background-color: white;
    border-radius: 5px;
    width: 90%;
}

.uploaded-responses-container p{
    margin-right: 10px;
}

.add-matrix-container{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;
    padding-bottom: 10px;
    margin: 20px 10px;
}

.button-container-matrix-question{
    margin-top: 10px;
}

.matrix-row-item-container{
    display: flex;
    align-items: center;
    width: 100%;
}

.matrix-row-item-container img{
    width: 15px;
    cursor: pointer;
}

.matrix-row-item-container input{
    width: 150px;
    box-shadow: none;
    border: none;
}

.matrix-column-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-right: 30px;
}

.matrix-column-title-container{
    margin-left: 150px;
    display: flex;
    justify-content: space-between;
}

.matrix-column-item-container{
    display: flex;
    align-items: center;
}

.matrix-column-item-container input{
    box-shadow: none;
    border: none;
    text-align: center;
}

.matrix-column-item-container img{
    width: 15px;
    cursor: pointer;
}

.add-matrix-item-container{
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.add-matrix-item-container img{
    cursor: pointer;
    height: 20px;
    margin-top: 5px;
}

#matrix-column-input{
    width: 100%
}

.section-container{
    width: 100%;
    border-radius: 5px;
    margin: 50px 0px;
}

.section-container input {
    width: 150px;
    box-shadow: none;
    border: none;
    background-color: transparent;
    font-size: 20px;
}

.section-container textarea{
    width: 90%;
    box-shadow: none;
    border: none;
    min-height: 30px;
    background-color: transparent;
}

#section-title-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#section-title-container input{
    width: 100%;
}

#section-title-container img{
    width: 20px;
    cursor: pointer;
    margin-right: 5px;
}

#section-dropzone{
    height: 50px;
    border: 1px dashed lightgray;
    display: flex;
    justify-content: center;
}

#section-dropzone p{
    text-align: center;
    font-size: 12px;
    color: gray;
}

#dropzone-title-container{
    display: flex;
    align-items: center;
}

#dropzone-title-container svg{
    height: 15px;
}

#dropzone-questions-container{
    margin-left: 10px;
}

#drag-out-of-section-container{
    display: flex;
    justify-content: center;
    padding: 10px;
    border: 1px dashed lightgray;
}

.questionnaire-suggestion-container{
    display: flex;
    align-items: center;
}

.questionnaire-suggestion-container img{
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-left: 5px;
}

.questionnaire-add-other-multiple-question{
    font-size: 12px;
    cursor: pointer;
    color: #63cadc;
}

.questionnaire-multiple-add-option-container{
    display: flex;
    align-items: center;
}

.questionnaire-add-other-multiple-question:hover{
    background-color: #c7e7ec;
    border-radius: 5px;
}

.muitple-delete-icon{
    height: 20px;
    width: 20px;
    cursor: pointer;
}

#multiple-question-other{
    width: 173px;
    margin-left: 5px;
}

.placeholder-row-multiple{
    height: 3px;
    width: 300px;
}

.order-questions-container{
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 50px 20px 20px 20px;
}

#delete-section-icon{
    cursor: pointer;
    height: 25px;
}

.order-questionnaire-field-item-container input{
    border: none;
    box-shadow: none;
    margin-bottom: 2px;
    padding: 2px;
    background-color: transparent;
}

.order-questionnaire-field-item-container p{
    margin: 0px;
}

#section-landing-container{
    display: flex;
    margin: 20px 0px 20px 40px;
}

#section-landing-in-container{
    padding-left: 5px;
    height: 50px;
    background-color: white;
    width: 48%;
    display: flex;
    align-items: center;
    border: 1px dotted lightgray;
    border-radius: 5px;
    justify-content: center;
} 

#section-landing-container p{
    font-size: 10px;
    text-align: center;
    margin-left: 5px;
}

#section-landing-container svg{
    height: 15px;
}

#draggable-item-input-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-right: 10px;
}

#order-questionnaire-section-title-input{
    font-size: 18px;
    padding-left: 10px;
}

#section-children-container{
    min-height: 50px;
}

#section-order-field-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#order-fields-item-container{
    border-bottom: 1px solid lightgray;
    padding: 15px;
}

#remove-field-from-section-icon{
    cursor: pointer;
    height: 20px;
}

#draggable-item-container{
    width: 100%;
}

#share-questionnaire-qrcode-icon{
    height: 30px;
    width: 30px;
    cursor: pointer;
}

#questionnaire-builder-description-textarea{
    width: 90%;
}

#edit-matrix-container{
    overflow-x: scroll;
}

#matrix-grid-container {
    display: grid;
    grid-template-columns: 150px repeat(6, 1fr);
    gap: 10px; /* Space between cells */
}

.matrix-grid {
    display: contents; /* Allows columns to span correctly */
}

.matrix-grid-header, .matrix-grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.matrix-grid-header input, .matrix-grid-item input {
    text-align: center;
    width: 30px;
    border: none;
    box-shadow: none;
}

.matrix-delete-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.matrix-grid-row {
    display: contents; /* This makes each row element follow the grid of the parent container */
}

#add-unsctructured-interviews-transcription-container{
    width: 100%;
}



  