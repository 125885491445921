#chatscreen-saving-container{
    display: flex;
    align-items: center;
}

#chatscreen-saving-container img{
    height: 35px;
    margin-right: 5px;
}

.message-container-user{
    display: flex;
    padding: 5px;
    flex-direction: row-reverse;
    margin-bottom: 15px;
}

.message-container-user img{
    height: 30px;
    margin-right: 10px;
    border-radius: 50%;
}

.message-container-user p{
    margin: 5px 5px 5px 10px !important;
}

.message-container-assistant{
    display: flex;
    padding: 5px;
    margin-bottom: 15px;
}

.message-container-assistant p{
    padding: 10px;
    background-color: white;
    width: 90%;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
    margin-top: 15px;
}

.message-container-assistant img{
    height: 25px;
    margin-right: 10px;
}

.message-container-assistant p{
    margin-left: 5px;
}

#chat-screen-text-delete-container{
    border-top: 1px solid #f4f4f4;
    padding-top: 5px;
}

#message-delete-icon{
    height: 15px;
    cursor: pointer;
    margin-left: auto;
    margin-right: 5px;
}

#chat-input-container{
    display: flex;
    align-items: center;
    border: 1px solid #f4f4f4;
    border-radius: 5px;
    padding: 10px;
    margin: 20px 10px 20px 0px;
    background-color: white;
}

#chat-input-container textarea{
    border: none;
    box-shadow: none;
    font-size: 12px;
    min-height: 50px;
    resize: vertical;
}

#chat-input-container input:focus {
    outline: none;
  }

#chat-input-container img{
    height: 25px;
    cursor: pointer
}

#chat-text-container{
    height: 65vh;
    overflow-y: scroll;
    padding: 10px;
    border-radius: 5px;
    background-color: #f3f7fd;
}

#chat-text-container p{
    font-size: 12px;
    margin-top: 0px;
}

#chat-text-container li{
    font-size: 12px;
}

#example-messages-container{
    display: flex;
    margin-top: 15px;
}

.example-message{
    border: 1px solid #f4f4f4;
    padding: 5px;
    margin: 5px;
    cursor: pointer;
    border-radius: 5px;
    background-color: white;
}

.example-message p{
    font-size: 12px;
    margin: 0px;
}

#example-question-title{
    font-weight: bold;
    margin-bottom: 5px;
    margin-right: 15px;
    font-size: 12px;
}

#example-messages-messages-container{
    display: flex;
    flex-wrap: wrap;
}

#chat-screen-container{
    margin: 30px 0px;
    display: flex;
    flex-direction: column;
    align-items: space-between;
}

#chatscreen-helpdesk-container{
    display: flex;
    flex-direction: column;
}

#chatscreen-helpdesk-container p{
    font-size: 12px;
    margin: 0px;
}

#chatscreen-helpdesk-container a{
    font-size: 12px;
    margin: 0px;
}

.ai-assistant-icon{
    cursor: pointer;
}

.impactai-container{
    height: 100%;
    width: 250px;
    transition: width 0.3s ease;
  }
  
  #impactai-toggle-container{
    display: flex;
    background-color: #f3f7fd;
    border-left: 1px solid #ededed;
    padding-left: 2px;
  }
  
  #impactai-toggle-container svg{
    margin-top: 50vh;
    cursor: pointer;
    fill: #808080;
  }
  
  #impactai-logo-container{
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    height: 100px;
  }
  
  #impactai-logo-container img{
    height: 30px;
    width: 30px;
    margin-right: 10px;
  }

  #closed-chatscreen-icon-container img{
    height: 20px;
    margin: 50px 10px 0px 10px;
    cursor: pointer;
  }

  .chat-text-options-user-container{
    padding: 10px;
    background-color: white;
    width: 90%;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 15px;
    margin-top: 15px;
    margin-right: 5px;
  }

  .chat-text-options-assistant-container{
    padding: 10px;
    background-color: white;
    width: 90%;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
    margin-top: 15px;
  }