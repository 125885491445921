.settings-notification-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e7e6e6;
    padding-top: 10px;
}

.settings-extra-functionality-container{
    border-bottom: 1px solid #e7e6e6;
    padding-top: 10px;
}

.settings-notifications-title-container{
    max-width: 50%;
}

.settings-color-box{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-bottom: 20px;
}

.setting-color-picker{
    width: 20px;
    height: 20px;
    cursor: pointer;
    padding: 0px;
    box-shadow: none;
}

.settings-color-picker-container{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100px;
}

.settings-color-container{
    display: flex;
    margin-bottom: 20px;
    justify-content: space-around;
}

.settings-color-tip-text{
    font-size: 10px;
    font-weight: bold;
}

.color-explainer-container{
    border-top: 1px dotted lightgray;
    padding: 10px;
    margin-top: 20px;
}

.profile-inner-container{
    padding: 20px;
    width: 95%;
    background: white;
    margin: 50px 0px;
    border-radius: 5px;
    border: 1px solid #f4f4f4;
  }

  .profile-inner-container h2{
    text-align: left;
  }

  .button-container-invite-members{
    margin-top: 20px !important;
    margin-bottom: 50px !important;
    justify-content: flex-start !important;
}

#adjust-photo-profile{
    border-radius: 50%;
    width: 100px;
    height: 100px;
}

.profile-button-container{
    display: flex;
    justify-content: center;
    border-bottom: 1px solid lightgray;
    padding-bottom: 30px;
    margin-bottom: 30px;
}

#delete-account-button{
    border-radius: 5px;
    background-color: darkred;
    color: white;
    border-color: darkred;
    margin: 5px;
  }