.count-container{
    display: flex;
    align-items: end;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;
}

.add-category-container img{
    width: 20px;
    cursor: pointer;
    margin-right: 10px;
}

.add-category-container input{
    border: 1px solid lightgray;
}

.analyse-category-words-container{
    display: flex;
}

.analyse-category-word{
    margin-right: 5px;
    cursor: pointer;
}

.selected-words-container{
    margin-bottom: 5px;
    align-items: center;
}

.selected-words-container img{
    width: 20px;
    margin-left: 10px;
    cursor: pointer;
}

.selected-word{
    text-decoration: line-through;
}

.development-item-container{
    border: 1px solid lightgray;
    border-radius: 5px;
    height: 150px;
    display: flex;
    align-items: center;
}

.options-container{
    margin-left: 90%;
    cursor: pointer;
}

.multiple-moment-item-container{
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 5px;
    height: 150px;
    display: flex;
    align-items: center;
}

.multiple-results-count-container h2{
    margin-left: 10px;
}

.results-detail-input-container{
    display: flex;
    align-items: center;
}

.results-detail-input-container img{
    width: 20px;
    margin-left: 10px;
    cursor: pointer;
}

.analyse-categorie-response-container{
    display: flex;
    align-items: center;
}

.analyse-categorie-response-container p{
    border: 1px solid lightgray;
    border-radius: 5px;
    background-color: white;
    padding: 10px;
    margin-top: 20px;
    width: 90%;
}

.analyse-categorie-response-container img{
    width: 20px;
    margin-right: 10px;
    cursor: pointer;
}

.respons-count-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;
    width: 90%;
}

.respons-count-container img{
    width: 20px;
    cursor: pointer;
}

.edit-paragraph-field-analysis-icon-container{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.edit-paragraph-field-analysis-icon-container img{
    width: 20px;
    cursor: pointer;
}

.measuremoment-scale-average-container h2{
    text-align: center !important;
}

.selected-scatter-point-container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 0px 0px 0px;
    width: 100%;
}

.selected-scatter-point-container p{
    margin: 0px 10px 0px 0px;
    font-size: 12px;

}

.selected-scatter-point-outer-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px 0px 0px 0px;
    width: 100%;
}

.scale-difference-total-container h2{
    text-align: center;
}   

.link-to-categorisation-container{
    display: flex;
    align-items: center;
}

.link-to-categorisation-container img{
    height: 25px;
    cursor: pointer;
    margin-right: 10px;
}

.link-to-categorisation-container p{
    cursor: pointer;
}

.analyse-icon{
    cursor: pointer;
}

.category-container{
    min-height: 400px;
}

.edit-option-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#analysis-words-suggestion-container{
    display: flex;
    align-items: center;
}

#analysis-words-suggestion-container svg{
    cursor: pointer;
}

#analyse-words-suggested-words-container{
    display: flex;
    border: 1px solid #f4f4f4;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

#field-meta-forms-item-container{
    display: flex;
    align-items: center;
}

#field-meta-forms-item-container p{
    margin-right: 10px;
}