#sync-detail-container{
    width: 90%;
}

#sync-detail-container h1, #sync-detail-container h2{
    text-align: left;
    margin-left: 0px;
}

#decline-sync-button{
    color: red;
}

.sync-content{
    margin-left: 33px;
}

.sync-item-container{
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.synced-output-title{
    color: #616161 !important;
    font-size: 15px !important;
    margin-left: 9px !important;
    font-weight: 600 !important;
}