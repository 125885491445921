.presence-data-container{
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.presence-data-container p{
    margin-left: 5px;
}

#presence-data-outer-container{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

#print-presence-report-period-container{
    display: flex;
    align-items: center;
    margin-right: 10px;
}

#print-presence-report-period-container p{
    margin-right: 5px;
}

.print-presence-report-data-container{
    display: flex;
    flex-direction: column ;
    align-items: center;
    margin-right: 10px;
}

#print-presence-report-data-container h1{
    text-align: left;
    margin-left: 0px;
}

#print-presence-report-data-container h2{
    text-align: left;
}

#print-presence-report-data-container{
    padding: 20px;
    border: 1px solid #f4f4f4;
    background-color: white;
}