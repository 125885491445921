#button-add-goal{
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.priority-color{
    height: 15px;
    width: 15px;
    border-radius: 50%;
    margin-right: 10px;
}

.priority-color-container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.task-detail-container{
    background-color: white;
    width: 80%;
    display: flex;
    flex-direction: column;
}

.task-detail-user-container{
    display: flex;
    align-items: center;
    cursor: pointer;
}

.task-detail-user-container img{
    height: 30px;
    width: 30px;
    margin-right: 10px;
}

.task-detail-inner-container{
    margin: 10px;
}

.appointed-container{
    display: flex;
}

.task-appointed-photo{
    height: 30px !important;
    width: 30px !important;
    border-radius: 50%;
}

.progression-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.progression-container-activity-detail{
  width: 100%;
  display: flex;
  flex-direction: column;
}

.task-overview-container{
    width: 83%;
}

.progression-container-goal-detail{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.page-container-activities{
  flex-direction: column;
}

.progressionbar-outer-bar{
    width: 80%;
    height: 30px;
    border: 1px solid lightgray;
    border-radius: 5px;
    margin-bottom: 30px;
    background-color: white;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    display: flex;
    align-items: center;
}

.progressionbar-outer-bar p{
    margin: 5px 0px 0px 5px;
    color: white;
    font-weight: bold;
}


.progressionbar-completed{
    background-color: #b2d7bb;
    height: 30px;
}

.activity-inner-container{
    margin: 10px 15px;
    width: 100%;
}

.mytask-overview-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.task-outer-container{
  width: 80%;
}

.button-activity-detail{
    margin-right: 5px;
    margin-left: auto;
}

.task-container{
  width: 100%;
  display: flex;
  border: 1px solid lightgray;
  background-color: white;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 10px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.task-description{
    width: 100%;
}

.activity-detail-effects-container{
    margin: 10px 50px 50px 50px;
}

.task-container img {
    height: 15px;
    width: 15px;
    cursor: pointer;
    margin-right: 10px;
}

#add-goal-sdg-container input{
    width: auto;
    box-shadow: none;
    margin-right: 10px;
}

.task-inner-container{
  margin: 5px 10px;
  display: flex;
  align-items: center;
  width: 100%;
}

#task-filter-container{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    width: 80%;
    margin-bottom: 30px;
}

.task-filter-inner-container{
    margin: 10px;
}

.task-filter-inner-container select{
    width: 250px;
}

#button-tasks-filter-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 75px;
    margin-left: 10px;
}

#button-tasks-filter-container button{
    border-radius: 3px;
    color: white;
    padding: 6px;
    font-weight: bold;
}

.active-tab{
    box-shadow: inset 0 1px 2px #d3d3d3;
    background-color: #a7bdbd26;
    margin-right: 5px;
  }

  .not-active-tab{
    border-top: 1px solid lightgray;
    margin-right: 5px;
  }

.project-group-container{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#tasks-outer-container{
    margin-bottom: 50px;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    padding: 20px;
    background-color: white;
    border-radius: 5px;
}

.instrument-card{
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    width: 85%;
    border: 1px solid #ebebeb;
}

#header-menu-container{
    display: flex;
    justify-content: center;
}

#add-task-icon{
    height: 15px;
    width: 15px;
    margin-right: 10px;
}

.add-task-container{
    cursor: pointer;
}

#input-task-edit-title{
    box-shadow: none;
    border: none;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 30px;
    color: #2F2C41;
    text-align: center;
    font-weight: bold;
}

.milestone-container{
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.milestone-container img{
    height: 20px;
    width: 20px;
}

.task-complete-date-container{
    display: flex;
    align-items: center;
    margin: 0px;
}

.gantt-container{
    display: flex;
    flex-direction: column;
    width: 83%;
}

.gantt-item-outer-container{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.gantt-item-titel-container{
    display: flex;
}

.gantt-item-titel-container p{
    margin: 0px;
}

.gantt-item-number{
    margin-right: 5px !important;
}

.gantt-item-progress-outer-container{
    width: 100%;
    border: 1px solid lightgray;
    border-top-left-radius: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 0px;
    height: 100%;
}

.gantt-item-progress-container{
    display: flex;
    justify-content: space-between;
    background-color: green;
    height: 100%;
    align-items: center;
    border-top-left-radius: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 0px;
    height: 100%;
}

.gantt-item-progress-container p{
    color: white;
    margin: 0px;
}

.item-meta-container{
    background-color: lightgray;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 35%;
    border-top-left-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 5px;
}

.item-meta-container p{
    margin-top: 0px;
    margin-bottom: 2px;
}

.date-bar-gantt-container{
    display: flex;
    justify-content: space-between;
    margin-left: 25%;
}

.gantt-item-title-progress{
    font-size: 10px;
    text-align: left;
    margin: 0px 0px 0px 10px;
}

.add-results-container{
    width: 95% !important;
}

.gantt-measure-moment-meta{
    font-size: 11px;
}