.menu-container{
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 5px;
    background-color: white;
}

#page-container{
    width: 100%;
    min-height: 100vh;
    padding: 0px 30px 30px 30px;
    overflow: scroll !important;
}

.icon{
    margin-right: 10px;
}

.icon-margin-left{
    margin-left: 20px;
    cursor: pointer;
}

.menu-item-container{
    border-radius: 5px;
    border: 1px solid lightgrey;
    padding: 5px;
}

.menu-item-title-container{
    display: flex;
    align-items: center;
}

.menu-item-title-container p{
    cursor: pointer;
}

.add-to-kpi-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.add-to-kpi-container input{
    margin-left: 10px;
    border: 1px solid lightgray;
    border-radius: 5px;
}

.table-position-item{
    color: #a5a5a5;
    width: 10px;
}

.tooltip-container {
    position: relative;
    display: inline-block;
    margin: 0px;
    width: 100%;
  }

  .tooltip-container p{
    color: white;
    font-size: 12px;
  }
  
  .tooltip {
    position: absolute;
    z-index: 1;
    background-color: black;
    color: white;
    padding: 8px;
    border-radius: 5px;
    left: 0;
    font-size: 12px;
  }

  .tooltip-trigger{
    margin: 0px;
  }

  .drag-icon{
    cursor: move;
  }

.menu-icon{
    height: 20px;
    width: 20px;
    margin-right: 10px;
}

.css-i4bv87-MuiSvgIcon-root {
  fill: #2F2C41;
}

.step-container img{
  width: 15px !important;
  height: 15px !important;
  margin: 10px;
}

body {
    margin: 0;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  h1{
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 30px;
    color: #616161;
    text-align: center;
    margin: 30px 20px;
  }
  
  h2{
    font-family: 'Roboto Condensed', sans-serif;
    text-align: center;
    font-size: 20px;
    color: #616161;
  }
  
  h3{
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 18px;
    color: #616161;
    font-weight: bold;
  }
  
  h4{
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 16px;
    color: #616161;
    font-weight: bold;
  }
  
  h5{
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 15px;
    color: #616161;
    font-weight: bold;
  }
  
  p{
    font-family: 'Poppins', sans-serif;
    color: #616161;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-align: left;
  }
  
  div{
    font-family: 'Poppins', sans-serif;
    color: #616161;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.5px;
    text-align: left;
  }
  
  a{
    font-family: 'Poppins', sans-serif;
    color: #616161;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.5px;
    text-decoration: none;
  }
  
  ul{
    text-align: left;
  }
  
  li{
    font-family: 'Poppins', sans-serif;
    color: #616161;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
  
  text{
    font-family: 'Poppins', sans-serif;
    color: #616161;
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
  
  .App, #root{
    height: 100%;
    background-color: #fafbff;
  }
  
  .main{
    display: flex;
    min-height: 91vh;
  }
  
  button{
    padding: 5px;
    border-radius: 5px;
    background-color: #63cadc;
    border: 1px solid #63cadc;
    color: white;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    width: auto;
    font-weight: bold;
  }
  
  .button-container-margin-top{
    margin: 30px 0px;
  }
  
  .button-clicked{
    padding: 5px;
    border-radius: 10px;
    background-color: green;
    border: 1px solid green;
    color: white;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    width: auto;
  }
  
  .button-minimal{
    padding: 5px;
    border-radius: 3px;
    background-color: white;
    border: 1px solid gray;
    color: gray;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    width: 100px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .button-simple{
    border: none;
    color: #63cadc;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    width: auto;
    background: none;
  }

  .button-simple-red{
    border: none;
    color: #fa9999;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    width: auto;
    background: none;
  }
  
  input{
    padding: 15px;
    border-radius: 3px;
    font-size: 15px;
    border: 1px solid lightgray;
    font-family: 'Poppins', sans-serif;
    color: #616161;
    box-shadow: inset 0 1px 2px lightgrey;
    width: 90%;
    font-weight: 500;
  }
  
  .input-classic{
    padding: 15px;
    border-radius: 3px;
    border: 1px solid lightgray;
    font-family: 'Poppins', sans-serif;
    color: #616161;
    box-shadow: none;
    width: 90%;
    font-size: 15px;
  }
  
  select{
    padding: 5px;
    border: 1px solid lightgray;
    border-radius: 5px;
    color: #616161;
    font-size: 15px;
    max-width: 80%;
    font-family: 'Poppins', sans-serif;
  }
  
  label{
    font-family: 'Poppins', sans-serif;
    color: #616161;
  }
  
  textarea{
    padding: 15px;
    font-size: 15px;
    border-radius: 5px;
    border: 1px solid lightgray;
    font-family: 'Poppins', sans-serif;
    color: #616161;
    width: 94%;
    box-shadow: inset 0 1px 2px grey;
    min-height: 100px;
  }
  
  .textarea-classic{
    padding: 15px;
    border-radius: 5px;
    border: 1px solid lightgray;
    font-family: 'Poppins', sans-serif;
    color: #616161;
    width: 94%;
    box-shadow: none;
    min-height: 100px;
  }
  
  textarea ::placeholder{
    color: lightgray;
  }
  
  ::placeholder {
    color: lightgray;
    opacity: 1; /* Firefox */
  }
  
  .input-radio{
    width: auto;
  }
  
  .divider{
    border-bottom: 1px solid lightgray;
    padding-bottom: 30px;
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .card-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 10px 40px 10px;
  }
  
  .card-header h2{
    margin-bottom: 0px;
  }
  
  .button-container{
    display: flex;
    justify-content: center;
    margin: auto auto 20px auto;
  }
  
  .button-container-top{
    margin-top: 20px;
  }
  
  .input-color{
    height: 70px;
    width: 70px;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .main-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .card-container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 85%;
  }
  
  .list-container{
    display: flex;
    flex-direction: column;
    width: 73%;
    padding-top: 30px;
    margin-bottom: 50px;
  }
  
  .dashboard-container{
    width: 95% !important;
    background-color: white;
    padding: 30px;
    border-radius: 5px;
    border: 1px solid #f4f4f4;
    margin-bottom: 20px;
  }

  .dashboard-container h2{
    text-align: left;
  }
  
  .list-container-wizard{
    width: 100%;
  }
  
  .reaction-area{
    width: 80%;
    min-height: 50vh;
  }
  
  .reaction-inner-container{
    display: flex;
  
  }

  .activity-meta-title-container img{
    width: 20px;
    height: 20px;
    margin-right: 15px;
  }

  .activity-meta-title-container svg{
    width: 20px;
    height: 20px;
    margin-right: 15px;
  }
  
  .active{
    padding: 3px 5px 3px 5px;
    border-radius: 5px;
    color: white;
  }

  .activeTitle{
    padding: 5px;
    border-radius: 5px;
    margin: 15px 0px;
  }

  .activeTitle h3{
    margin: 0px;
    color: white;
  }
  
  .editable-text-input{
    border: none;
    box-shadow: none;
    text-align: center;
    font-size: 30px;
    color: #2F2C41
  }
  
  .table-container{
    width: 100%;
    overflow-x: auto;
    margin-top: 20px;
    max-height: 480px;
    overflow-y: scroll;
  }

  .table-container h2{
    text-align: left;
  }
  
  table{
    width: 100%;
    background-color: white;
  }
  
  table th {
    padding: 5px 10px;
    text-align: left;
    background-color: #63cadc;
    color: white;
   
  }
  
  td{
    margin-right: 10px;
    vertical-align: middle;
  }
  
  .table-delete-icon{
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin-left: 10px;
  }
  
  table input{
    border: none;
    box-shadow: none;
    margin: 0px 0px;
    padding: 15px 10px;
    background: transparent;
  }
  
  table select{
    border: none;
    background:transparent;
  }
  
  table textarea {
    border: none;
    background:transparent;
    box-shadow: none;
  }
  
  tr p{
    margin: 0px 5px;
  }
  
  tr div{
    margin: 0px 5px;
  }
  
  .table-item-title-container{
    display: flex;
    align-items: center;
  }
  
  table tr:nth-child(even){background-color: #f2f2f2;}
  
  table tr:hover {background-color: #ddd;}

  .page-header{
    margin: 0px 0px 50px 0px;
    display: flex;
    /* border-bottom: 1px solid #ebebeb; */
    width: -webkit-fill-available;
    align-items: center;
}

#table-tooltip-p{
    font-size: 15px;
    color: #616161;
}

.page-header-section{
  margin: 0px;
  border-bottom: none;
}

.page-header-section h1{
  margin: 0px 0px 0px 20px;
}

.page-header h1{
    font-size: 40px;
    text-align: left;
}

.page-header h2{
  margin-right: 10px;
}

.page-header img, .page-header svg{
    height: 50px;
    width: 50px;
    fill: #616161;
}

.edit-icon-header-container{
    display: flex;
    justify-content: flex-end;
}

.edit-icon-header-container img, .edit-icon-header-container svg{
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin-left: 10px;
}

.updrage-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
    width: 100%;
    
}

#upgrade-inner-container{
  background-color: white;
  padding: 30px;
  border-radius: 5px;
  border: 1px solid #f4f4f4;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.updrage-container img{
  height: 200px;
}

iframe{
  border-radius: 5px;;
}

#low-width-screen-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.custom-tooltip{
  background-color: white;
  border: 1px solid #f4f4f4;
  border-radius: 5px;
  padding: 0px 20px;
}

.label{
  font-weight: bold;
}

#delete-modal-container img{
 height: 30px;
}

#delete-modal-container input{
  width: 15px;
  margin-right: 10px;
}

#delete-modal-container button{
  margin-right: 10px;
}

#delete-modal-container h2{
  margin-bottom: 50px;
}

#delete-modal-delete-button{
  background-color: red;
  border-color: red;
}

#delete-modal-button-container{
  display: flex;
  justify-content: end;
  background-color: #f4f4f4;
  padding: 20px;
  margin: 50px -20px -20px -20px;
}

#delete-modal-title-container{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.close-modal-icon-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #f4f4f4;
  border-radius: 50%;
  padding: 5px 5px 5px 5px;
  width: 10px;
  height: 10px;
  text-align: center;
  margin-left: auto;
  margin-right: -15px;
  margin-top: -15px;
  margin-bottom: 0px;
  cursor: pointer;
  background-color: white;
}

.close-modal-icon-container p{
  color: #f4f4f4;
}

.modal-bottom-bar{
  background-color: #f4f4f4;
  margin: 20px -20px -40px -20px;
  padding: 20px;
  display: flex;
  justify-content: space-around;
}

.modal-close-button{
  background-color: red;
  border-color: red;
  margin-right: 10px;
}

.modal-section-container{
  margin-top: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #f4f4f4;
}

.table-edit-container{
  display: flex;
  align-items: center;
}

.table-edit-container svg{
  width: 20px;
  cursor: pointer;
}

.table-edit-container img{
  width: 20px;
  cursor: pointer;
}

#graph-loading-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 100%;
}

#graph-loading-container img{
  height: 50px;
  width: 50px;
}

#footer-container{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background-color: #f3f7fd;
  align-items: center;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

#footer-container a{
  padding: 5px;
  font-size: 12px;
  color: black;
  font-weight: 400;
}

#footer-container img{
  width: 10px;
  height: 10px;
}

.breadcrumb-item-container{
  display: flex;
  align-items: center;
  cursor: pointer;
}

#breadcrumb-container{
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
  margin-top: auto;
  bottom: 0;
  position: sticky;
  background-color: #fafbff;
  padding: 15px 30px;
}

#breadcrumb-container p{
  font-size: 12px;
}

#breadcrumb-container svg{
  height: 15px;
}

.breadcrumb-steps-container{
  margin-left: 10px;
}

.breadcrumb-steps-container p{
  margin: 0px;
}

#breadcrumb-section-container{
  margin: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.single-row-table{
  background-color: white;
}

#copy-link-container{
  display: flex;
  align-items: center;
  justify-content: start;
}

#copy-link-container input{
  width: 150px;
  border: 1px dotted lightgray;
}

.copied{
  font-size: 10px;
  color: green;
}

#modal-button-container{
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  background-color: #f4f4f4;
  padding: 20px;
  margin: 50px -20px -20px -20px;
}

#modal-cancel-button{
  margin-right: 10px;
  background-color: red;
  border: red
}

.list-top-row-container{
  display: flex;
  align-items: center;
}

.list-top-row-container p{
  margin-left: 10px;
  margin-top: 5px;
  cursor: pointer;
}

.placeholder-row-questionnaire{
  width: 100%;
  height: 5px;
  
}

.th-title-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.th-title-container p{
  color: white;
  font-weight: bold;
}

.th-title-container svg{
  fill: white;
  cursor: pointer;
}

.placeholder-div{
  height: 3px;
  width: 100%;
}

#loading-icon{
  height: 40px;
  width: 40px;
}

#backbutton-container{
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  margin-left: 2px;
}

#backbutton-container img{
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

#input-container{
  width: 100%;
}

.add-row-container{
  height: 50px;
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
  padding: 0px 8px;
}

.add-row-container img{
  height: 20px;
  width: 20px;
  margin-right: 10px;
  cursor: pointer;
}

.add-row-container:hover{
  background-color: #e0e0e0;
}

.add-options-container{
  padding: 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
  background-color: white;
  flex-direction: column;
  width: 300px;
  z-index: 10;
}

.add-options-container-item-container{
  display: flex;
  align-items: center;
  cursor: pointer;
}

.add-options-container-item-container img{
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.add-options-container-item-container p{
  margin-bottom: 15px ;
}

.loader-svg{
  height: 100px;
  width: 100px;
}

.table-p{
  color: #616161 !important;
  font-size: 15px !important;
  padding: 20px 0px !important;
}

.page-header-new{
  margin: 0px 0px 50px 0px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ebebeb;
  width: 100%;
  width: -webkit-fill-available;
}

#page-header-title-container{
  display: flex;
  align-items: center;
}

#page-header-title-container svg{
  height: 50px;
  width: 50px;
  fill: #616161;
}

#page-header-title-container h1{
  font-size: 40px;
  margin-left: 0px;
}

#page-header-title-container p{
  font-size: 18px;
}

#page-header-impact-ai-icon{
  height: 20px;
  margin: 0px 10px;
}

#page-notice-container{
  display: flex;
  align-items: center;
  background-color: #d5e6fa;
  padding: 20px;
  border-radius: 5px;
  margin: 20px 0px;
  width: 95%;
}

#page-notice-container svg{
  margin-right: 10px;
  font-size: 30px;
}

.add-item-table-row-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #f2f2f2;
}

.add-item-table-row-container img{
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.add-item-table-row-container:hover{
  background-color: lightgray;
}

.add-options-upload-buttons-container{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
  background-color: #f2f2f2;
  margin: 20px -10px -10px -10px;
}

.add-options-upload-buttons-container button:first-child {
  background-color: red;
  color: white; 
}

.add-options-upload-buttons-container button:last-child {
  background-color: green;
  color: white; 
  margin-left: 10px;
}

#page-header-impact-ai-container{
  border: 1px solid transparent;
  padding: 1px;
  background-color: white;
  margin-bottom: 10px;
  width: 250px;
  display: flex;
  align-items: center;
  border-radius: 15px;
  background-image: linear-gradient(white, white), linear-gradient(45deg, #f48183, #47acc3); 
  background-origin: border-box;
  background-clip: content-box, border-box;
}

#page-header-impact-ai-container img{
  height: 25px;
  margin: 5px 10px 5px 10px;
  cursor: pointer;
}

#page-header-impact-ai-container p{
  margin: 0px;
  cursor: pointer;
}

#table-loading-icon{
  height: 30px;
  width: 30px;
  margin-left: 20px;
}

.table-delete-container{
  display: flex;
}