.output-title{
    width: 100%;
    padding-bottom: 5px;
    text-align: center;
}

#impact-progress-goal-container h2{
    text-align: center;
}

.toc-container{
    display: flex;
    width: 100%;
    margin-top: 30px;
    overflow-x: scroll;
}

.toc-sub-container{
    width: 100%;
}

.arrow-up-icon{
    width: 25px;
    margin-top: 10px;
}

.impact-dashboard-output-container{
    padding: 0px 10px;
    border-radius: 5px;
    background-color: white;
    border: 1px solid #ebebeb;
    width: 100%;
    margin-bottom: 5px;
}

.impact-dashboard-output-container p{
    text-align: left;
}

#toc-goal-item-container{
    font-weight: bold;
}

.toc-options-item-container{
    margin-bottom: 15px;
}

.toc-items-container{
    display: flex;
    flex-wrap: wrap;
}

.toc-item-container{
    display: flex;
}

.effect-term-short{
    margin-right: -10px;
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.effect-term-short p{
    color: white;
    padding: 5px;
    margin: 0px;
    font-size: 11px;
}

.effect-term-long{
    margin-right: -10px;
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.effect-term-long p{
    color: white;
    padding: 5px;
    margin: 0px;
    font-size: 11px;
}

#toc-arrow-icon{
    width: 25px;
    margin-top: 15px;
    margin-right: 20px;
}