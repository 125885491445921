.select-activity-container-persona-goal-dashboard{
    margin-left: 20px;
}

#register-presence-reason-correction-input{
    margin-top: 20px;
    margin-right: 5px;
    width: 84%;
}

.register-presence-time-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.register-presence-time-container p{
    margin-right: 5px;
}