#persona-goal-progress-textarea-container{
    width: 100%;
    height: 600px;
}

#persona-goal-progress-textarea-container textarea{
    width: 100%;
    height: 100%;
    font-size: 16px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: none;
}