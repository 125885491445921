.register-precense-options-container{
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-right: 20px;
}

.register-precense-options-container img{
    width: 30px;
    height: 30px;
    margin-right: 5px;
}

#check-precense-container{
    margin-left: auto;
    margin-right: 5px;
}

#register-presence-check-icon{
    width: 20px;
    height: 20px;
}

#report-user-meta-container p{
    font-weight: bold;
}

#dashboard-presence-container{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f4f4f4;
}

#dashboard-presence-times-container{
    display: flex;
    align-items: center;
    margin-left: 20px;
}

#dashboard-presence-times-container p{
    margin-right: 10px;
}

#dashboard-presence-name-container{
    width: 150px;
}

#dashboard-presence-name-container p{
    font-weight: bold;
}

#persona-dashboard-presence-today-container{
    display: flex;
}

#persona-dashboard-presence-today-time-container{
    display: flex;
    align-items: center;
    margin-left: 5px;
}

#register-presence-container{
    display: flex;
    align-items: center;
    margin-left: 20px;
}

#register-presence-is-present-button{
    margin-left: 10px;
    color: green;
    cursor: pointer;
}

#register-presence-not-present-button{
    margin-left: 10px;
    color: red;
    cursor: pointer;
}

#register-presence-is-present-button:hover{
    font-weight: bold;
}

#register-presence-not-present-button:hover{
    font-weight: bold;
}

#presence-report-item-container{
    display: flex;
    align-items: center;
}

#presence-report-item-container h3{
    margin-right: 10px;
}

#presence-report-difference-p{
    margin-left: 10px;
    font-weight: bold;
}

#presence-is-checked-notice-container{
    display: flex;
    align-items: center;
    margin-left: 10px;
    color: green;
}

#presence-is-checked-notice-container svg{
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

#persona-dashboard-day-name{
    margin-right: 5px;
}

#timeline-container{
    display: flex;
    width: 100%;
}

#timeline-activities-container{
    width: 100%;
    border-bottom: 1px solid gray;
    margin: 0px 10px 65px 10px;
}

#timeline-current-date{
    border-right: 3px solid gray;
    height: 10px;
}

#timeline-current-date-container{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

#timeline-current-date-container p{
    margin-right: -35px;
}

#date-range-selecttor-container{
    display: flex;
    align-items: center;
    margin-left: 0px;
}

.select-date-container{
    display: flex;
    align-items: center;
    margin: 20px 10px 20px 0px;
}

.select-date-container input{
    width: 150px;
    margin-right: 10px;
    border: none;
    box-shadow: none;
}

.select-date-container p{
    margin-right: 5px;
}

#persona-presence-options-container{
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-top: 50px;
    padding-top: 20px;
    border-top: 1px solid #f4f4f4;
}

#persona-presence-options-container svg{
    margin-right: 10px;
    cursor: pointer;

}

#persona-goal-item-container h3{
    margin-left: 35px;
    margin-top: 30px;
}

.dashboard-goal-title-container{
    padding: 0px 10px;
    border: 1px solid #f4f4f4;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#presence-report-detail-times-input-container{
    display: flex;
    align-items: center;
}

#presence-report-detail-times-input-container input{
    width: 150px;
    margin-right: 10px;
    border: none;
    box-shadow: none;
}

#presence-report-detail-options-container{
    margin-top: 30px;
    border-top: 1px solid #f4f4f4;
    padding-top: 10px;
}

#vpresence-report-detail-options-container svg{
    cursor: pointer;
}