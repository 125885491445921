#questionniare-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 97vh;
    padding-top: 50px;
}

#page-header-open-questionnaire{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px 0px 20px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #f4f4f4;
    width: 80%;
}

#page-header-open-questionnaire p{
    text-align: center;
}

#compagny-logo{
    width: 100%;
    width: 150px;
}

.field-title-container{
    display: flex;
    align-items: center;
}

.field-title-container h2{
    margin-right: 10px;
    text-align: left;
}

#options-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 5px;
}

#options-container span{
    display: flex;
    align-items: center;
}

#options-container input{
    width: auto;
}

#options-container label{
    margin-left: 5px;
}

.questionnaire-button-container{
    margin-top: 30px;
    display: flex
}

.questionnaire-button-container button{
    margin-right: 10px;
}

#questionnaire-fields-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin: 50px;
    padding: 20px;
    border: 1px solid #f4f4f4;
    border-radius: 5px;
    background-color: white;
    overflow-y: scroll;
}

#questionnaire-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

#questionnaire-field-section-item-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.top-level-field-container{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.question-container{
    width: 100%;
    margin-bottom: 30px;
}

.question-field-outer-container{
    width: 100%;
}

.header-questionnaire{
   text-align: center;
   width: 80%;
   padding: 20px 20px 0px 20px;
   border-radius: 5px;
   border: 1px solid #f4f4f4;
   background-color: white;
}

#scale-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.matrix-column-title-container{
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-left: 100px;
}

#section-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 20px;
}

#question-type-label-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

#question-type-label-container input{
    margin-bottom: 10px;
}

#section-outer-container{
    width: 100%;
}

#progress-bar-container {
    height: 10px;
    width: 100%;
    margin: 20px -20px 0px -20px; 
    border-bottom-left-radius: 5px;
}

#progress-bar {
    background-color: #00bfa5;
    height: 100%;
    border-bottom-left-radius: 5px;
    margin-left: -20px;
}

#multiple-other-container{
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0px 0px 0px 20px;
}

#multiple-other-container p{
    margin: 0px;
}

#matrix-row-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-right: 100px;
}

#send-check-container{
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0px 0px 0px 20px
}

#send-check-container p{
    margin: 0px;
    font-size: 10px;
}

#send-check-container svg{
    height: 20px;
    width: 20px;
    margin-right: 10px;
}

#questionnaire-field-outer-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#questionniare-send-button{
    padding: 20px;
    font-size: 20px;
    background-color: #00bfa5;
}

#send-button-inner-container{
    display: flex;
    align-items: center;
}

#send-button-inner-container svg{
    height: 20px;
    width: 20px;
    margin-right: 10px;
    color: white;
}

#send-button-inner-container p{
    margin: 0px;
    color: white;
    font-size: 22px;
}

.question-radio-input{
    width: 30px;
}

.matrix-grid-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  
  .matrix-grid-header.first-column {
    justify-content: flex-start; /* Align horizontally at the start */
    align-items: flex-start;     /* Align vertically at the start */
  }

  .matrix-grid-header.first-column p{
    overflow: auto;
  }

  #send-copy-container{
    padding: 20px;
    border: 1px solid #f4f4f4;
    border-radius: 5px;
    margin-top: 50px;
  }

  #send-copy-container button{
    margin-top: 10px;
  }

  #responses-send-container{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .questionnaire-field-container{
    break-inside: avoid;
  }
  