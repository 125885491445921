.dropdown-content {
    display: none;
    margin: 5px 0 0 0;
    z-index: 1;
}

.dropdown-content input{
    width: 10px;
}

.select-box{
    cursor: pointer;
}
