.graph-wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.graph-total-responses-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.graph-total-responses-container svg{
    margin-right: 5px;
}

.graph-total-responses-container p{
    margin: 0;
    font-size: 0.8rem;
}