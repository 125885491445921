.effects-meta-item-outer-container{
    border-bottom: 1px solid lightgray;
}

.effects-meta-item-inner-container{
    display: flex;
    align-items: center;
}

.effects-meta-item-inner-container p{
    margin-right: auto;
}

.effect-datapoint-item-container{
    border-bottom: none;
}

.effect-stakeholders-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.effect-stakeholders-item img{
    width: 20px;
    height: 15px;
}

.effect-stakeholders-item p{
    margin: 0px;
}

.effect-meta-select-stakeholder-container{
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.effect-meta-select-stakeholder-container select{
    font-size: 12px;
}

.effect-meta-select-stakeholder-container button{
    font-size: 10px;
}

.effectdata-container{
    display: flex;
    flex-wrap: wrap;
}

.show-more-icon-effects-items-wizard{
    width: 15px;
    cursor: pointer;
}

.effect-title-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.effect-title-container input{
    background-color: transparent;
    border: none;
    box-shadow: none;
    font-size: 18px;
}

.effect-title-container img{
    width: 15px;
    height: 15px;
    margin-left: 10px;
}

.checkbox-select-effects-item-container{
    display: flex;
}

.checkbox-select-effects-item-container input{
    margin-right: 10px;
}

#edit-term-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#edit-term-container svg{
    cursor: pointer;
}

.add-standard-effect-modal-sub-title p{
    margin-left: 10px;
}

#add-standardozed-affect-modal-pre-title{
    text-align: center;
}

.effect-selector-title-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #f4f4f4;
    padding: 0px 10px;
    cursor: pointer;
}

.effect-selector-title-container:hover{
    background-color: #f4f4f4;
}

#effects-targetgroups-container{
    display: flex;
    align-items: center;
}

#effects-targetgroups-container img{
    width: 15px;
    height: 15px;
}

#meetstandaard-efeect-questions-item-container{
    display: flex;
    align-items: center;
}

#meetstandaard-efeect-questions-item-container p{
    margin-right: 5px;
}