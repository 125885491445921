#persona-report-score-effects-item-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 35px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
}

#persona-report-score-effects-item-container svg{
    margin-left: 20px;
    margin-right: 5px;
}

#persona-presence-report-detail-author-container{
    display: flex;
    align-items: center;
}

#persona-presence-report-detail-author-container p{
    margin-right: 5px;
    font-size: 12px;
}

#persona-report-indicators-fieldtype-container{
    margin-left: 35px;
    margin-top: 20px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #f4f4f4;
}