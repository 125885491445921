#fund-builder-name-input {
  width: 90%;
}

.fund-selector-item-container input{
    width: auto;
    margin-right: 10px;
}

#fundbuilder-fund-logo{
  width: 200px;
  margin-bottom: 20px;
}