#guide-container{
    display: flex;
    align-items: center;
}

#guide-container svg{
    cursor: pointer;
}


