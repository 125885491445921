.insights-main-type-container{
    width: 100%;
}

#select-research-input{
    width: 20px;
    border: none;
    background-color: transparent;
    box-shadow: none;
}

.filtered-results-results-container{
    border: 1px solid #e0e0e0;
}

.start-insights-button-container{
    display: flex;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
}

.start-insights-button-container svg{
    margin-right: 10px;
}

.start-insights-button-container p{
    text-decoration: underline;
}

.insights-analysis-content-container{
    padding: 10px;
    border: 1px dotted lightgray;
    min-height: 80px;
    border-radius: 5px;
    margin-bottom: 30px;
}

#specified-results-corresponding-inputs-question {
    font-weight: bold;
}