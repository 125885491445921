#notifications-container{
    width: 90%;
}

.notification-item-container{
    background-color: white;
    padding: 30px;
    border-radius: 5px;
    border: 1px solid #f4f4f4;
    margin-bottom: 20px;
}

.notification-item-container h2{
    text-align: left;
}