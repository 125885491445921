.source-data-sources-item-container{
    display: flex;
    align-items: center;
}

.data-search-links-item-container{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;
    margin-top: 5px;
}

.data-search-links-item-container li{
    margin-right: 10px;
}

.data-search-links-item-container img{
    height: 20px;
    cursor: pointer;
    margin-bottom: 2px;
}