#middle-bar{
    width: 60%;
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.pointer{
    cursor: pointer;
}

#middle-bar h1{
    text-align: center;
}

.goal-title-container{
    display: flex;
    align-items: center;
}

.goal-title-container img{
    height: 25px;
    width: 25px;
    margin-right: 10px;
}

#not-approved-container{
    width: 100%;
    margin-top: 60px;
}

#password-reset-button-modal{
    font-size: 10px;
    text-decoration: underline;
    cursor: pointer;
}

#password-reset-button{
    cursor: pointer;
}

#button-reset-password{
    margin-top: 20px;
}

#error-message-reset-password{
    color: red;
}

#succes-message-password-reset{
    color: green;
}

.hide-message-options{
    display: none;
}

.profile-results{
    width: 80% !important;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.about-me-user-text h3{
    text-align: left;
}

.button-about-me{
    margin-top: 20px;
}

.about-me-inner-container{
    margin: 30px;
}

.about-me-section{
    margin: 20px;
}
.delete-button-simple{
    color: red;
    border: none;
}

.impactpath-container{
    width: 80%;
}

.login-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    min-height: 90vh;
  }

#sub-title-new-client{
    color: #f48183;
}

.calendar-navigation-next-prev-container img{
    height: 20px;
}

.calendar-navigation-next-prev-container{
    margin-right: 20px;
    cursor: pointer;
}

.arrow-down-container{
    display: flex;
    justify-content: center;
}

.arrow-down-container img{
    width: 100px;
}

.logo-container img{
    max-width: 150px;
}

#start-image{
    border-radius: 5px;
    width: 100%;
}

.profile-settings-delete-icon{
    width: 20px;
    height: 20px;
    margin-top: 10px;
    cursor: pointer;
}

.activity-details-container{
    margin-left: 5%;
}

.activity-details-inner-container{
    border-left: gray 1px solid;
    padding-left: 10px;
}

.button-container-colors{
    margin-top: 10px;
}

.impact-path-section-container{
    background-color: white;
    padding: 20px;
    margin-bottom: 10px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%)
}

.card{
    height: auto;
    width: 200px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    margin: 30px 10px 30px 10px;
    border-radius: 5px;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .button-input-container{
      margin: 20px 20px 20px 0px;
      width: 100%;
      display: flex;
  }

  .input-label-container{
      display: flex;
      align-items: center;
  }

  .article{
    height: auto;
    min-height: 300px;
    width: -webkit-fill-available;
    border: 1px solid #ebebeb;
    margin: 30px 10px 30px 10px;
    border-radius: 5px;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
    padding: 20px;
  }

  .delete-channel{
      height: 30px;
      width: 30px;
      cursor: pointer;
  }

  .list{
    height: 80px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    margin: 0px 5px 10px 5px;
    border-radius: 5px;
    background-color: #FFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .list-body-container{
    padding: 20px;
    width: 100%;
  }

  .list-button-container{
    margin-right: 20px;
  }

  .profile{
    background-color: white;
    margin-top: 20px;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
  }

  .client-styles-container{
      display: flex;
      flex-wrap: wrap;
  }

  .subtitle-header{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: -35px;
  }

  .results-container{
      padding: 20px;
  }

  .list img{
      width: 25%;
      height: 100%;
  }

  .card img{
      width: 100%;
      height: 150px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      object-fit: cover;
  }

#profile-header-photo{
    height: 80px;
    width: 80px;
    border-radius: 50%;
}

.ReactModal__Overlay{
    z-index: 5;
}

.tox .tox-editor-container {
    z-index: 1;
}

.add-image-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.article-detail-banner{
    width: 100%;
    margin: 0px 0px;
}

.userrole-users-container{
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid lightgray;
    padding: 5px;
    border-radius: 3px;
    margin-bottom: 3px;
}

.userrole-select{
    padding: 5px;
    border: 1px solid lightgray;
    border-radius: 3px;
    color: gray;
}

.userrole-users-container img{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.userrole-users-delete-button{
    color: red;
    margin-left: auto;
    margin-right: 0px;
    cursor: pointer;
    font-size: 10px;
    width: 20px !important;
    height: 20px !important;
    border-radius: 0px !important;
}

#user-compagny{
    cursor: pointer;
}

.delete-button-my-introductions-container img{
    width: 25px;
    align-self: left;
    cursor: pointer;
    margin-left: 2px;
}

.notification-sidebar-container{
    display: flex;
}

.notification-sidebar-container p{
    margin: 0px 0px 0px 3px;
    padding: 2px;
    font-size: 11px;
}

.userrole-users-approve-button{
    color: green;
    margin-left: auto;
    margin-right: 0px;
    cursor: pointer;
    font-size: 10px;
}



.button-userrole-container{
    margin-top: 20px;
}

  .plus-icon{
      width: 35px;
      cursor: pointer;
      margin-top: 20px;
  }

  #add-goal-form{
      margin: 30px;
      width: 90%;
  }

  .goal-list{
      align-items: center;
  }

  #introductions-textarea{
    width: 100%;
    margin-bottom: 20px;
  }

  .photo-container-profile{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 20px 0px;
  }

  .goalcard-body-div{
      padding: 20px;
      text-align: center;
      word-break: break-all;
  }

  .button-container-register{
      margin: 20px;
  }

  .type-container{
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px;
  }

  .save-bar{
      bottom: 0;
      position: sticky;
      background-color: white;
      display: flex;
      justify-content: center;
      padding: 20px;
  }

  .sub-title{
      text-align: center;
  }

  #login-form{
      width: 300px;
  }

  .spinner-container{
    display: flex;
    justify-content: center;
    margin: 30px;
  }

  .spinner-container img{
      height: auto;
      width: 300px;
      border-radius: 5px;
  }

  .save-bar button{
      border-radius: 3px;
      background-color: green;
      color: white;
      border-color: green;
  }

  .auth-name{
      margin-right: 5px;
      color: #959595;
      cursor: pointer;
  }

  .event-detail-button{
      margin: 30px;
  }

  .message-outer-container{
      width: 80%;
  }

  .goal-user-meta-container{
      display: flex;
      justify-content: space-between;
      width: 90%;
      border: 1px solid lightgray;
  }

  .goal-user-container{
      display: flex;
  }

  .ais-SearchBox{
      display: flex;
      justify-content: center;
      margin-bottom: 50px;
  }

  .hit-container{
      border: 1px solid lightgray;
      border-radius: 5px;
      margin: 10px;
      padding: 10px;
  }

  .hit-container h2{
      text-align: left;
  }

  #button-notifications-container{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
  }

  .right-panel{
      width: 100%;
      margin-top: 50px;
  }

  .ais-SearchBox-form{
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .ais-SearchBox-submit{
      display: none;
  }

  .ais-SearchBox-reset{
      display: none;
  }

  .registration-field-input{
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid lightgray;
      padding: 5px;
      height: 40px;
  }

  .registration-field-input img{
      height: 15px;
      width: 15px;
      cursor: pointer;
  }


  .field-textarea-container{
    display: flex;
    justify-content: space-between;
    border: 1px solid lightgray;
    padding: 5px 10px 40px 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .field-input-container{
    display: flex;
    justify-content: space-between;
    border: 1px solid lightgray;
    padding: 5px 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .add-registration-field-container{
      display: flex;
      justify-content: left;
      align-items: center;
      background-color: #a7bdbd26;
      margin: 0px 0px 10px 10px;
      padding: 5px 25px 5px 5px;
  }

  .add-registration-field-container img{
      border: 1px solid lightgray;
      margin-left: auto;
      margin-right: 0px;
  }

  .drag-icon{
      margin-left: 0px !important;
      margin-right: 20px !important;
      border: none !important;
      height: 20px;
      width: 20px;
  }

  #logo-subscription-container{
      display: flex;
      align-items: center;
  }

  #logo-subscription-container p{
      color: rgb(99, 202, 220);
      font-size: 12px;
      font-weight: bold;
  }

  .premium-notice-outer-container{
      display: flex;
      flex-direction: column;
      align-items: center;
  }

  #premium-notice-container{
      height: 300px;
      border-radius: 5px;
      width: 100%;
      padding: 20px;
      box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
      background-color: #63cadc;
      display: flex;
      flex-direction: column;
      align-items: center;
  }

  #premium-notice-container h1{
    color: white;
  }

  #premium-notice-container h2{
    color: white;
  }

  #premium-notice-container p{
    color: white;
  }

  #premium-notice-container button{
    border-radius: 5px;
    border: 1px solid #f48183;
    padding: 5px;
    font-size: 16px;
    font-weight: 600;
    margin: 5px;
    background-color: #f48183;
    cursor: pointer;
    color: white;
  }

  #premium-notice-container a{
    margin: auto 0px 10px 0px;
  }
  
  .ais-Hits-list{
    list-style-type: none;
    padding-left: 0px;
    }

  .message-options-inner-container{
      display: none;
      justify-content: space-between;
      background-color: #a7bdbd26;
  }

  .main-login-register{
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    background-color: #a7bdbd26;
  }

  .select-organisation{
      cursor: pointer;
  }

  .channel-div-login-register{
      margin: 20px;
  }

  .channel-inner-div-login-register{
    display: flex;
    justify-content: center;
  }

  .channel-inner-div-login-register p{
      margin: 5px;
  }

  .login-register-container{
      display: flex;
      align-items: center;
      cursor: pointer;
      margin: 0px 20px;
  }

  .login-register-container img{
      width: 30px;
  }

  .message-options-container{
    margin-top: 10px;
  }

.like-icon-container-profile{
    display: flex;
    justify-content: center;
    margin: 10px 10px 20px 10px;
}

.like-icon-container-profile img{
    width: 30px;
    height: 30px;
}

  .chat-screen a{
      text-decoration: underline;
  }

  .send-as-mail-container button{
      border-color: lightgray;
      border-radius: 3px;
      color: gray;
      font-size: 12px;
  }

  .send-as-mail-container select{
      padding: 4px;
      border-radius: 3px;
      border-color: lightgray;
  }

  .goal-progress-container{
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #a7bdbd26;
      width: 100%;
      margin-bottom: 20px;
      padding: 5px;
      border-radius: 5px;
  }

  .goal-user-container img{
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 10px;
  }

  .chats-overview-container{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 90%;
      min-height: 150px;
      cursor: pointer;
      border-radius: 5px;
      border-top: 1px solid lightgray;
  }

  .messagebar-outer-cointainer{
      width: 80%;
  }

  .chatpartner-meta-container{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      flex-direction: row;
  }

  .chat-overview-username{
      margin-right: 10px;
      color: #2F2C41;
  }

  .chats-overview-container img{
      width: 30px !important;
      height: 30px !important;
      border-radius: 50%;
      margin-right: 10px;
  }

  #log-out-button{
    border-radius: 3px;
    background-color: red;
    color: white;
    border-color: red;
    margin: 5px;
  }

  .article-meta-container{
      width: 80%;
  }

  #event-detail-timestamp{
      text-align: center;
  }

  .settings-header{
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
  }

  .channel-container{
      display: flex;
      margin: 10px;
      padding: 10px;
      border: 1px solid lightgrey;
      border-radius: 5px;
      align-items: center;
      justify-content: space-between;
  }

  .update-button-channel{
      display: none;
  }

  .goal-card-banner{
      width: 100%;
      height: 50%;
  }

  .new-group-container{
      margin-bottom: 20px;
  }

  .new-channel-container{
      margin-bottom: 20px;
  }

  .channel-container img{
      width: 20px;
      height: 20px;
      margin-left: 10px;
      cursor: pointer;
      justify-self: flex-end;
  }

  .layout-container{
      display: flex;
      justify-content: space-evenly;
  }

  .type-container img{
      width: 20px;
      height: 20px;
  }

  .contribution-card-ul{
      list-style-type: none;
  }

  .introduction-card{
    align-items: center;
    padding: 0px 0px 20px 0px;
    background-color: white;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    border-radius: 5px;
    margin: 30px;
    padding: 10px;
    text-align: center;
    width: 80%;
  }

  #search-bar{
      width: 70%;
      border-radius: 15px;
      padding: 10px;
  }

  #search-container{
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
  }

  .introduction-reaction-button{
      margin: 20px;
  }

  .timestamp-public-profile{
    font-size: 12px;
    text-align: center;
    margin-bottom: 30px;
}

  .color-container{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 10px;
  }

  #members-container img {
      height: 40px;
      width: 40px;
      margin-right: 20px;
      border-radius: 50%;
      object-fit: cover;
  }

  .activity-card-banner{
      width: 100%;
  }

  .public-profile-photo{
      border-radius: 50%;
      align-self: center;
      margin: 30px;
      width: 300px;
      height: 300px;
      object-fit: cover;
  }

  .public-profile-container{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 80%;
  }

  .button-verification{
      margin: 20px;
  }

  .start-banner h2{
      margin-bottom: 50px;
  }

  .no-email-button-container{
      display: flex;
      flex-direction: column;
      align-items: center;
      border-top:  1px solid lightgray;
      margin-top: 100px;
      padding-bottom: 15px;
      border-bottom:  1px solid lightgray;
  }

  .no-email-button-container p{
    color: #2F2C41;
  }



  .settings-inner-container{
      padding: 20px;
  }

  .radio-input-container{
      display: flex;
  }

  .radio-input-container input{
      width: auto;
      box-shadow: none;
  }

  .profile-auth-profile{
      width: 80%;
      padding: 20px;
      border: 1px solid #f4f4f4;
      margin: 50px;
  }

  .profile-auth-profile h2{
      text-align: left;
  }

  .profile-auth-goal{
    width: 60%;
    padding: 20px;
  }

  .allActivity-user-photo{
      width: 35px !important ;
      height: 35px;
      border-radius: 50%;
      cursor: pointer;
      margin-right: 10px;
  }

  .new-messages{
      color: green;
      margin-left: 10px;
  }

  .description-container{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 10px;
  }

  #still-no-mail-notice{
      color: #959595;
  }

  .message-inner-container{
      width: 80%;
  }

  .username{
      margin-right: 5px;
      cursor: pointer;
      font-weight: bold;
  }

  .user-meta-goal-card{
      display: flex;
  }

  .user-goal-card{
      margin-left: 5px;
      cursor: pointer;
      color: #d11237;
  }

  .user-goal-card:hover{
      font-weight: bold;
  }

  .auth-message-container{
      display: flex;
      align-items: center;
  }

  .auth-photo-container img{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;
  }

  .goal-detail-container{
      padding-bottom: 90px;
  }

  .all-members-member-container{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
  }

  .all-members-member-container img{
      height: 30px;
      width: 30px;
      border-radius: 50%;
      margin-right: 10px;
      object-fit: cover;
  }

  .group-container{
      width: 100%;
      margin-top: 20px;
      border-radius: 5px;
      flex-direction: column;
      display: flex;
      align-items: center;

  }

  .left-side-bar-full-screen{
    -webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  .chatpartner-container{
      display: flex;
      justify-content: center;
      align-items: center;
  }

  #about-me-container{
      padding-bottom: 100px;
  }

  .user-photo{
    height: 50px;
    width: 50px !important;
    border-radius: 50%;
    margin-right: 10px;
  }

  .notification-card{
    width: 80%;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    margin: 30px 30px 30px 30px;
    border-radius: 5px;
    background-color: #FFF;
    text-align: center;
    padding: 20px;
  }

  .notification-card-inner-container{
      padding: 20px;
  }

  .notification-card p{
      text-align: center;
  }

  .notification-card ul{
      text-align: center;
      padding-inline-start: 0px;
  }

  .user-meta-container{
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
  }

  .notification-counter{
      border-radius: 50%;
      background-color: green;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px 0px -7px 10px;
      width: 15px;
      height: 15px;
      font-size: 11px;
  }

  .notification-counter-small{
    border-radius: 50%;
    background-color: green;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 0px -7px 10px;
    width: 10px;
    height: 10px;
}

  .login-logo{
      width: 80%;
  }

  .introductioncard-timestamp{
      color: lightgray;
  }

  .left-side-bar-toggle img{
      width: 40px;
      height: 40px;
  }

  .left-side-bar-toggle{
      display: flex;
      margin: 0px 0px 0px 0px;
      cursor: pointer;
  }

  .block{
    margin: 20px;
    background: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    cursor: pointer;
    width: 30%;
  }

  .block p{
      font-size: 18px;
  }

  #add-item-channel-settings-detail-button{
      width: 30px;
      height: 30px;
      cursor: pointer;
  }

  .effect-title-container{
      display: flex;
      align-items: center;
  }

  .groupsettings-detail-member-container img{
      height: 30px;
      width: 30px;
      margin-right: 10px;
      border-radius: 50%;
  }

  .groupsettings-detail-member-container{
      display: flex;
      cursor: pointer;
      align-items: center;
  }

  .effect-title-container img{
      width: 30px;
      height: 30px;
      margin-right: 10px;
  }

  #search-icon{
      margin-top: 20px;
  }

  .select-profiel-field-container{
      display: flex;
      align-items: center;
  }

  .select-profiel-field-container input{
      width: 15px;
      box-shadow: none;
  }

  .select-profiel-field-container label{
      display: flex;
      align-items: center;
  }

  .add-registration-field-container p{
      margin: 20px;
  }

  #notification-false{
      display: none;
  }

  .button-login{
      margin-top: 20px;
  }

  .start-banner{
      margin-bottom: 50px;
  }

  .button-goal-message-container{
      margin-top: 20px;
  }

  .notification-message{
      cursor: pointer;
  }

  .notification-goal{
      cursor: pointer;
  }

  .notification-timestamp{
      color: lightgray;
  }

  .activity-container{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 80%;
      margin: 20px 0px;
      background-color: white;
      box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
      border-radius: 5px;
  }

  .goal-detail-banner{
      width: 100%;
  }

  .activity-container h2{
      text-align: left;
  }

  .goal-activity-header{
      flex-direction: column;
  }

  .like-container{
      padding: 10px;
      margin-bottom: 20px;
      border-radius: 5px;
  }

  .like-count-container{
      display: flex;
      justify-content: center;
      margin: 20px;
      cursor: pointer;

  }

  .like-count-container p{
      padding: 2px;
      font-size: 11px;
      margin-left: 0px;
  }

  .like-count-container img{
      width: 30px;
      height: 30px;
  }

  .previous-message-container{
      display: flex;
      margin-top: 20px;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      width: 85%;
  }

  .previous-message-container img{
      width: 20px;
      height: 20px;
      margin-right: 10px;
  }

  .header-title-container{
      display: flex;
      flex-direction: column;
      align-items: center;
  }

  .settings-inner-container h2{
      text-align: left;
  }

  .settings-inner-container p{
      margin-bottom:  0px;
  }

  .graph-div-contributions-container{
      width: 80%;
      box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
      background-color: white;
      border-radius: 5px;
      padding: 5px;
  }

  .sdg-type{
      color: #2F2C41;
      margin-left: 10px;
  }

  .back-to-community-container{
      display: flex;
      align-items: center;
      justify-content: flex-start;
  }

  .back-to-community-container img{
      width: 20px;
      height: auto;
      margin-right: 5px;
  }

  .my-message-message-container{
      cursor: pointer;
  }

  .introductions-list{
      min-height: 100px;
      flex-direction: column;
      width: 80%;
  }

  .introduction-list-inner-container{
      padding: 15px;
  }

  .task-detail-header{
      flex-direction: column;
  }

  .introductions-list input{
      width: 70%;
  }

  .introductions-list img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  .introductions-container{
      background-color: white;
      border-radius: 5px;
      width: 80%;
      margin-bottom: 20px;
      box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  }

  .activity-card-body{
      font-size: 16px;
      margin-top: 40px;
      align-self: center;
  }

  #plus-icon-goal-settings{
      width: 30px;
      height: 30px;
      margin-left: 10px;
  }

  #input-introduction-card{
      margin-bottom: 20px;
      width: 80%;
  }

  .rule-input-container{
      display: flex;
  }

  .rule-input-container p{
      margin-right: 10px;
  }

  #introduction-input-container{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 20px;
  }

  .like-goal-container{
      display: flex;
  }

  .contributions-amount-profile{
      text-align: center;
      cursor: pointer;
  }

  #button-add-event{
      margin-bottom: 30px;
  }

  .activity-card-button{
      color: green;
      cursor: pointer;
      align-self: center;
  }

  .like-goal-container select{
      margin: 5px;
      border-radius: 3px;
      border-color: lightgrey;
      max-width: 30%;
  }

  #register-event-button-container{
      display: flex;
      justify-content: center;
  }

  .event-detail-button-signedup{
      background-color: white;
      border-color: lightgray;
      color: lightgray;
      margin: 20px;
      cursor: default;
  }

  #show-calendar-button-hidden{
      display: none;
  }

  #show-calendar-button{
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
  }

  #show-calendar-button p{
      text-align: center;
  }

  #show-calendar-button img{
      width: 20px;
      margin-right: 10px;
  }

  .user-container-activity-card{
      display: flex;
      align-items: center;
  }

  .activity-card-timestamp{
      text-align: center;
  }

  #add-channel-container input {
      margin: 20px 0px;
  }

  .list-banner{
      margin-bottom: 0px;
  }

  .card-banner{
    margin-bottom: -58px;
}

  .article-card-user-container img{
    height: 70px;
    width: 70px;
    border-radius: 50%;
    border: 2px solid white;
    background-color: white;
  }
  
  .list-card-timestamp{
      color: lightgray;
  }

  .event-meta-container{
      text-align: start;
  }

  .location-container{
      display: flex !important;
      flex-direction: row !important;
      align-items: center;
      text-align: start;
      justify-content: start;
  }

  .location-container img{
      height: 30px;
      width: 30px;
  }

  .location-container ul{
      list-style-type: none;
  }

  .card-inner-container{
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
}

.card-inner-container button{
   align-self: center;
}

  .left-side-bar-toggle{
    display: none;
    }

.bottom-bar{
    display: none;
    position: sticky;
    bottom: 0;
    z-index: 2;
    background-color: white;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%);
}

.group-outer-container{
    display: flex;
    width: 100%;
}

.SDG-select{
    width: 100%;
    padding: 5px;
    border-radius: 3px;
    border-color: lightgray;
    color: gray;
    font-size: 14px;
}

.more-sdg{
    cursor: pointer;
    font-size: 12px;
}

.add-channel-icon{
    cursor: pointer;
    width: 40px;
    height: 40px;
}

.my-message{
    cursor: pointer;
}

.layout-container{
    display: flex;
    justify-content: space-evenly;
    cursor: pointer;
}

.layout-container img{
    width: 200px;
    height: 205px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.button-container-public-profile{
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.events-signups-container{
    display: flex;
    align-items: center;
    border: 1px solid lightgray;
    padding: 5px;
    border-radius: 3px;
}

.signup-container{
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
    display: flex;
    align-items: center;
    background-color: white;
}

.my-event-card button{
    margin-right: 0px;
    margin-left: auto;
}

.my-event-card h3{
    margin-right: 20px;
}

.my-event-list-container{
    width: 85%;
}

.signup-container img{
    width: 60px;
    margin-right: 10px;
}

.signup-timestamp{
    margin-right: 0px;
    margin-left:  auto;
}

.events-signups-container p{
    margin: 0px 10px 0px 0px;
    cursor: pointer;
}

.events-signups-container button{
    margin-right: 0px;
    margin-left: auto;
}

.layout-container h5{
    text-align: center;
}

.layout-container img:hover{
    box-shadow: none;
}

.left-sidebar-container-mobile{
    display: none;
}

.reaction-button{
    margin-top: 20px;
}

.send-as-mail-button{
    border-color: lightgray;
    border-radius: 3px;
    color: gray;
    font-size: 12px;
}

.task-calendar-container{
    width: 85%;
}

.my-messages-delete-button{
    cursor: pointer;
    width: 20px ;
    height: 20px ;
    margin: 0px 10px 10px 10px;
}

.user-compagnies-container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.user-compagnies-container img{
    width: 15px;
    height: 15px;
    margin-left: 10px;
}

.new-client-container{
    background-color: rgba(131,237,255,.0784313725490196);
    padding: 50px 50px 50px 50px;
}

.new-client-container p{
    font-size: 20px;;
}

#title-new-client{
    color: #206d7e;
    font-size: 40px;
}

.new-client-button-container button{
    border-radius: 5px;
    border: 1px solid #f48183;
    padding: 5px;
    font-size: 16px;
    font-weight: 600;
    margin: 5px;
    color: white;
    background-color: #f48183;
    cursor: pointer;
}

.dashboard-detail-item-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;
}


/* @media only screen and (max-width: 1024px){

    .top-bar-logo{
        margin-left: 30px;
    }

    .left-side-bar-toggle{
        display: block;
    }

    .right-sidebar{
        display: none;
      }

    .bottom-bar{
        display: block;
    }

    .icon-bar-big-screen{
        display: none !important;
    }

    .iconbar-external-container{
        display: none;
    }

    .profile-photo img{
        margin-right: 5px;
    }

    .description-container{
        flex-direction: column;
    }

    .group-container{
        width: 100%;
        border-radius: 0px;
        margin: 10px;
        flex-direction: column;
    }

    .profile{
        width: 80%;
        border-radius: 0px;
    }

    .notification-card {
        width: 80%;
    }

    .notification-card h2{
        text-align: left;
        word-break: break-word;
    }

    .channel-inner-div a{
        font-size: 18px;
    }

    .channel-div h3{
        font-size: 20px;
    }

    .reaction-inner-container{
        flex-direction: column;
    }

    .auth-message-container {
        justify-content: center;
    }

    .message-card-timestamp {
        margin: 0px 0px 0px 0px;
    }

    .article-body-container {
        width: 90%;
    }

    .auth-message-container img {
        margin-right: 0px;
    }

    .massage-container{
        margin-left: 0px;
      }

    .left-sidebar-container-mobile{
        display: flex;
        width: 100%;
        margin-left: 20px;
    }

    .left-side-bar{
        display: none;
    }

    .message-outer-container {
        width: 100%;
    }

    .list-inner-container {
        overflow: hidden;
    }

    .article-card-user-container {
        min-width: 0%;
    }

    .chat-screen {
        padding: 20px 0px;
        width: 90%;
    }

    .back-to-community-container p{
        font-size: 18px;
    }

    .calendar-event-container {
        width: 20px;
    }

    .calendar-navigation-container button {
        margin-right: 0px;
    }

}

   */