.left-side-bar{
    margin: 0px 40px 0px 30px;
}

.channel-div{
    max-width: 200px;
}

.channel-inner-div{
    display: flex;
    flex-direction: column;
}

.channel-inner-div p{
    margin-bottom: 5px;
    padding-left: 5px;
    font-weight: 600;
    cursor: pointer;
    margin: 1.5px;
}

.channel-inner-div p:hover{
  background-color: white;
  border-radius: 5px;
  padding-right: 5px;
}

.plus-icon-sidebar{
    width: 15px;
    cursor: pointer;
}

.left-sidebar-container{
    width: 90%;
    transition: width 0.3s ease;
  }
  
  #leftsidebar-logo-container{
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: left;
    margin-right: 20px;
    height: 100px;
  }

  .leftsidebar-seperator{
    height: 3px;
    background-color: #fafbff;
  }

  .top-bar-logo{
    max-height: 50px;
    margin: 20px 20px 20px 30px;
    cursor: pointer;
    max-width: 200px;
  }

  #left-side-bar-toggle-container{
    display: flex;
    background-color: #f3f7fd;
    border-right: 1px solid #ededed;
    padding-right: 2px;
  }

  #leftsidebar-toggle-item-container svg{
    margin-top: 50px;
    cursor: pointer;
    fill: #808080;
  }

  .channel-inner-div-empty{
    height: 20px;
  }
  
  .channel-items-container{
    content-visibility: visible;
    transition: visibility 0.5s linear;
    margin-top: -10px;
    margin-left: 40px;
  }

  .expand-menu-icon{
    width: 15px;
    fill: lightgray !important;
    margin-top: 6px;
    cursor: pointer;
  }

  .expand-menu-icon:hover{
    fill: #2f2c41 !important;
  }

  .nav-title-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav-title-container img{
    margin-left: 5px;
}

.activity-meta-title-container{
  display: flex;
  align-items: center;
  width: 100%;
}

.channel-inner-div a:hover{
  background-color: white;
  border-radius: 5px;
  padding-right: 5px;
}

#expand-menu-icon{
  height: 15px;
  width: 15px;
  cursor: pointer;
}

.sidebar-title-inner-container{
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 15px 0px 15px 0px;
}

.sidebar-title-inner-container h3{
  margin: 0px;
}

.sidebar-title-inner-container h3:hover{
  cursor: pointer;
}

.sidebar-title-inner-container svg{
  margin-right: 5px;
}

.top-bar-logo-small{
  max-height: 50px;
  margin: 20px 20px 20px 30px;
  cursor: pointer;
  max-width: 200px;
}

#closed-sidebar-icons-container{
  margin-top: 50px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;

}

#closed-sidebar-icons-container svg{
  margin-bottom: 30px;
}

#closed-sidebar-home-icon{
  margin-bottom: 55px !important;
  margin-right: 10px;
}

#sidebar-compagny-name-small h3{
  font-size: 26px;
  margin-bottom: 50px;
  margin-top: -3px;
}

.sidebar-title-hover-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0px 5px;
  margin: 0px;
  border-radius: 15px;
}

.sidebar-title-hover-container svg{
  margin-right: 10px;
}



